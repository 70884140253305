import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import useAuthorizeContent from './use-authorize'
import api from 'services/api'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'

const useStoryContent = () => {
  const { storyId } = useParams()
  const { accessToken: googleAccessToken, isLoading: authorizationIsLoading } =
    useAuthorizeContent()
  const [user] = useAuthState(getAuth())

  const { isLoading, data, error } = useQuery(
    ['content', storyId],
    async () => {
      const accessToken = await user.getIdToken()
      const { article } = await api.fetch(`/articles/${storyId}`, {
        accessToken,
      })

      if (article.content_id) {
        return api.fetch(`/articles/${storyId}/content`, {
          accessToken,
        })
      }

      if (article.permissions.owns) {
        return api.fetch(`/articles/${storyId}/content`, {
          accessToken,
          method: 'POST',
          headers: {
            'x-edito-g-access-token': googleAccessToken,
          },
        })
      }

      return null
    },
    {
      enabled: !authorizationIsLoading,
    }
  )

  return {
    isLoading,
    content: data?.content,
    error,
  }
}

export default useStoryContent
