import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './intl'
import './firebase'

import moment from 'moment'
import 'moment/locale/fr'
import { QueryCache, ReactQueryCacheProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query-devtools'

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN

moment.locale('fr', {
  calendar: {
    sameDay: '[aujourd’hui à] LT',
    nextDay: '[demain à] LT',
    nextWeek: 'dddd [à] LT',
    lastDay: '[hier à] LT',
    lastWeek: 'dddd [dernier à] LT',
    sameElse: '[le] dddd Do MMMM',
  },
})

if (process.env.NODE_ENV === 'production') {
  require('@sentry/browser').init({
    dsn: SENTRY_DSN,
  })
}

const queryCache = new QueryCache()

ReactDOM.render(
  <React.StrictMode>
    <ReactQueryCacheProvider queryCache={queryCache}>
      {process.env.NODE_ENV !== 'production' && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}

      <App />
    </ReactQueryCacheProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
