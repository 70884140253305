import React from 'react'
import { FormContext } from 'react-hook-form'
import StorySummary from 'domains/stories/summary'
import useStoryModal from 'hooks/calendar/use-story-modal'

export default function StorySummaryModal({ storyId }) {
  const { methods, onSubmit, isLoading, projects } = useStoryModal(storyId)

  if (isLoading) {
    return null
  }

  return (
    <FormContext {...methods}>
      <form onSubmit={onSubmit}>
        <StorySummary editable onBlur={onSubmit} projects={projects} />
      </form>
    </FormContext>
  )
}
