import React from 'react'
import { FormContext } from 'react-hook-form'
import { useStoryMember } from './hooks'
import Member from 'domains/members/list/item'

export default function StoryMember({ name, email, pictureUrl, role, roleId }) {
  const { methods, onUpdate, onDelete } = useStoryMember({ role, roleId })

  return (
    <FormContext {...methods}>
      <Member
        name={name}
        email={email}
        pictureUrl={pictureUrl}
        role={role}
        onUpdate={onUpdate}
        onDelete={onDelete}
      />
    </FormContext>
  )
}
