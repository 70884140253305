import React from 'react'
import Checkbox from 'components/checkbox/checkbox'
import { withIntl } from 'services/intl'
import { FormattedMessage } from 'react-intl'
import { Filter, ChevronDown } from 'react-feather'
import Dropdown from 'components/dropdown/dropdown'
import useStoryFilters from './use-filters'

const Trigger = (
  <button className="text-silver uppercase font-medium text-sm">
    <Filter className="mr-2 inline" size={16} />

    <span className="mr-2">
      <FormattedMessage
        id="stories.filters.show"
        defaultMessage="Show filters"
      />
    </span>

    <ChevronDown className="inline" size={18} />
  </button>
)

const STATUSES = ['draft', 'submitted', 'validated']
const StoryFilters = () => {
  const {
    isLoading,
    tags,
    onTagClick,
    onArchiveChange,
    uiState,
    onStatusClick,
    isStatusIncluded,
  } = useStoryFilters()

  if (isLoading) {
    return null
  }

  return (
    <Dropdown trigger={Trigger} className="relative">
      <div className="absolute shadow-md rounded bg-white p-4 w-64">
        <div className="mb-4">
          <div className="mb-1">
            <FormattedMessage id="stories.filters.tags" defaultMessage="Tags" />
          </div>

          <div>
            {tags.map((tag) => (
              <Checkbox
                key={tag.id}
                onChange={onTagClick}
                checked={uiState.filter.tags.includes(tag.name)}
                value={tag.name}
              >
                {tag.name}
              </Checkbox>
            ))}
          </div>
        </div>

        <div className="mb-4">
          <div className="mb-1">
            <FormattedMessage
              id="stories.filters.status"
              defaultMessage="Status"
            />
          </div>

          <div>
            {STATUSES.map((status) => (
              <Checkbox
                key={status}
                onChange={onStatusClick}
                checked={isStatusIncluded(status)}
                value={status}
              >
                <FormattedMessage
                  id={`stories.filters.status.${status}`}
                  defaultMessage={status}
                />
              </Checkbox>
            ))}
          </div>
        </div>

        <div>
          <label className="mb-1 flex items-center text-sm uppercase font-medium cursor-pointer">
            <Checkbox
              checked={uiState.filter.includeArchived}
              onChange={onArchiveChange}
            >
              <FormattedMessage
                id="stories.filters.showArchived"
                defaultMessage="Show archived stories"
              />
            </Checkbox>
          </label>
        </div>
      </div>
    </Dropdown>
  )
}

export default withIntl(require('./translations.json'))(StoryFilters)
