import React from 'react'
import ReactSelect from 'react-select'

const styles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    border: 0,
    cursor: 'pointer',
    '&:hover': {
      borderColor: '#E8ECEF',
    },
    '&:focus': {
      borderColor: '#E8ECEF',
    },
    '&:active': {
      borderColor: '#E8ECEF',
    },
    minHeight: 'auto',
    boxShadow: 0,
  }),
  menu: (styles) => ({
    ...styles,
    border: 'none',
    boxShadow: '0 4px 11px hsla(0,0%,0%,0.1)',
  }),
  placeholder: (styles) => ({
    ...styles,
    fontWeight: 400,
    color: '#98A9BC',
    margin: 0,
  }),
  option: (styles) => ({
    ...styles,
    background: 'none',
    color: '#1B1E24',
    fontWeight: 500,
    '&:hover': {
      color: '#4D7CFE',
      cursor: 'pointer',
    },
  }),
  singleValue: (styles) => ({
    ...styles,
    fontWeight: 400,
    color: '#1B1E24',
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: 0,
  }),
  input: (styles) => ({
    ...styles,
    padding: 0,
    margin: 0,
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: '0 4px',
  }),
  clearIndicator: (styles) => ({
    ...styles,
    padding: '0 4px',
  }),
}

const InlineSelect = ({ components = {}, ...props }) => (
  <ReactSelect
    {...props}
    styles={styles}
    components={{
      IndicatorSeparator: null,
      ...components,
    }}
  />
)

export default InlineSelect
