import React, { useState, useCallback, useEffect } from 'react'
import Header from './main/Header'
import Sidebar from './main/Sidebar'
import Footer from './main/Footer'
import { withIntl } from 'services/intl'
import ReactSidebar from 'react-sidebar'

const mql = window.matchMedia(`(min-width: 1280px)`)

const MainLayout = ({ children }) => {
  const [sideBarState, setSideBarState] = useState({
    sideBarIsDocked: mql.matches,
    sideBarIsOpen: mql.matches,
  })

  const handleOpen = (open) => {
    setSideBarState((state) => ({
      ...state,
      sideBarIsOpen: open,
    }))
  }

  const handleClick = useCallback((e) => {
    setSideBarState((state) => {
      const sideBarShouldOpen = !state.sideBarIsOpen

      return {
        ...state,
        sideBarIsOpen: sideBarShouldOpen,
        sideBarIsDocked: mql.matches && sideBarShouldOpen,
      }
    })
  }, [])

  const handleMediaQueryChange = () =>
    setSideBarState({ sideBarIsDocked: mql.matches, sideBarIsOpen: false })

  useEffect(() => {
    mql.addListener(handleMediaQueryChange)
    return () => mql.removeListener(handleMediaQueryChange)
  }, [])

  return (
    <ReactSidebar
      sidebar={<Sidebar />}
      open={sideBarState.sideBarIsOpen}
      docked={sideBarState.sideBarIsDocked}
      onSetOpen={handleOpen}
      shadow={false}
    >
      <div className="bg-lightBackground min-h-screen relative">
        <div className="h-full pb-16">
          <Header onDockClick={handleClick} />
          <div className="py-8 px-3 lg:p-8">{children}</div>
        </div>

        <div className="absolute inset-x-0 bottom-0">
          <Footer />
        </div>
      </div>
    </ReactSidebar>
  )
}

export default withIntl(require('./main/translations.json'))(MainLayout)
