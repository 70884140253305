import React from 'react'
import { useForm } from 'react-hook-form'
import { useIntl, FormattedMessage } from 'react-intl'
import classnames from 'classnames'
import ErrorMessage from 'components/error-message/error-message'

export default function CreateProjectForm({ onSubmit, error, disabled }) {
  const { register, handleSubmit, errors } = useForm()
  const intl = useIntl()

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="md:w-2/3 lg:w-1/2 md:mx-auto xl:w-2/5"
    >
      <div className="text-xl mb-8 text-center">
        <FormattedMessage
          id="projects.create.form.name"
          defaultMessage="Name your project"
        />
      </div>

      <div className="mb-8">
        <input
          ref={register({ required: 'required' })}
          name="name"
          className={classnames('border w-full rounded font-medium p-4', {
            'border-pink': !!errors.name,
            'border-outline': !errors.name,
          })}
          placeholder={intl.formatMessage({
            id: 'projects.create.form.name.placeholder',
            defaultMessage: "What's your story?",
          })}
        />

        <ErrorMessage errors={errors} name="name" />
      </div>

      {error && <div className="text-pink mb-1">{error}</div>}

      <div className="text-center">
        <button
          className="w-full h-12 rounded uppercase font-medium bg-primary hover:bg-primary-dark text-white disabled:text-silver disabled:bg-background"
          disabled={disabled}
        >
          <FormattedMessage
            id="projects.create.form.submit"
            defaultMessage="Start your story"
          />
        </button>
      </div>
    </form>
  )
}
