import React from 'react'

export default function Footer() {
  const currentYear = new Date().getFullYear()
  return (
    <div className="h-16 bg-white w-full text-gray text-sm flex items-center px-8">
      <span>&copy;&nbsp;{currentYear}</span>
      <span>&nbsp;</span>
      <span>Edito - all rights reserved.</span>
    </div>
  )
}
