import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import api from 'services/api'
import { useParams } from 'react-router-dom'
import { PROJECT_COLOR_OPTIONS_MAP } from 'services/project-colors'
import { useMutation, useQuery, useQueryCache } from 'react-query'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'

const useProjectSettings = () => {
  const [user] = useAuthState(getAuth())
  const { projectId } = useParams()
  const methods = useForm()
  const [showSavedMessage, setShowSavedMessage] = useState(false)
  const timeoutRef = useRef(null)
  const queryCache = useQueryCache()

  const { data, isLoading } = useQuery(
    ['projects', projectId],
    async () => {
      const accessToken = await user.getIdToken()
      return api.fetch(`/projects/${projectId}`, { accessToken })
    },
    {
      onSuccess: ({ project }) =>
        methods.reset({
          name: project.name,
          color: {
            value: project.color,
            label: PROJECT_COLOR_OPTIONS_MAP[project.color],
          },
        }),
    }
  )

  const [updateProject] = useMutation(
    async ({ name, color, storiesEventAtAttribute }) => {
      const projectData = {
        project: {
          name,
          color: color && color.value,
          articles_event_at_attribute:
            storiesEventAtAttribute && storiesEventAtAttribute.value,
        },
      }

      const accessToken = await user.getIdToken()

      return api.fetch(`/projects/${projectId}`, {
        method: 'PUT',
        body: JSON.stringify(projectData),
        accessToken,
      })
    },
    {
      onSuccess: (data) => {
        clearTimeout(timeoutRef.current)

        setShowSavedMessage(true)
        timeoutRef.current = setTimeout(() => {
          setShowSavedMessage(false)
        }, 1000)

        queryCache.setQueryData(['projects', projectId], data)
      },
      onError: (error) => {
        if (error.errorCode !== 'resource_invalid') {
          throw error
        }

        const fieldErrors = error.details.field_errors
        methods.setError(
          fieldErrors.map(({ field, code }) => ({
            name: field,
            message: code,
          }))
        )
      },
    }
  )

  const [archiveProject] = useMutation(
    async (project) => {
      const path = `/projects/${projectId}/${
        project.archived_at ? 'unarchive' : 'archive'
      }`

      const accessToken = await user.getIdToken()

      return api.fetch(path, {
        method: 'POST',
        accessToken,
      })
    },
    {
      onSuccess: (data) =>
        queryCache.setQueryData(['projects', projectId], data),
    }
  )

  const handleSubmit = methods.handleSubmit(updateProject)
  const handleArchive = (project) => {
    archiveProject(project)
  }

  return {
    isLoading,
    project: data?.project,
    methods,
    onSubmit: handleSubmit,
    showSavedMessage,
    onArchive: handleArchive,
  }
}

export default useProjectSettings
