import React from 'react'
import FullCalendar from '@fullcalendar/react'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import frLocale from '@fullcalendar/core/locales/fr'
import { useIntl } from 'react-intl'

export default function ListCalendar({ events, onEventDrop, onEventClick }) {
  const intl = useIntl()
  return (
    <FullCalendar
      defaultView="listMonth"
      plugins={[listPlugin, interactionPlugin]}
      events={events}
      eventStartEditable
      height="parent"
      buttonText={{
        today: intl.formatMessage({
          id: 'stories.calendar.today',
          defaultMessage: 'Today',
        }),
        month: intl.formatMessage({
          id: 'stories.calendar.month',
          defaultMessage: 'Month',
        }),
        week: intl.formatMessage({
          id: 'stories.calendar.week',
          defaultMessage: 'Week',
        }),
      }}
      header={{
        left: 'title',
        right: 'prev,next myCustomButton',
      }}
      eventDrop={onEventDrop}
      timeZone="local"
      locales={[frLocale]}
      locale="fr"
      eventClick={onEventClick}
      eventTimeFormat={{
        hour: '2-digit',
        minute: '2-digit',
        omitZeroMinute: false,
        meridiem: 'narrow',
      }}
      eventRender={({ el, event }) => {
        el.style.borderColor = event.borderColor
        el.style.borderLeftWidth = '3px'
        el.classList.add('px-4', 'hover:bg-transparent', 'cursor-pointer')

        el.querySelector('.fc-list-item-marker').classList.add('hidden')
        el.querySelector('.fc-widget-content').classList.add('border-0')
      }}
      listDayFormat={{
        day: 'numeric',
        weekday: 'long',
      }}
      listDayAltFormat={false}
    />
  )
}
