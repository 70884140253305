import React from 'react'
import { useQuery } from 'react-query'
import qs from 'query-string'
import ActivitiesList from 'domains/activities/list'
import api from 'services/api'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'

const useActivities = () => {
  const [user] = useAuthState(getAuth())

  const { data, isLoading } = useQuery(
    ['activities', { sort: '-created_at', all_pages: null }],
    async () => {
      const accessToken = await user.getIdToken()

      return api.fetch(
        qs.stringifyUrl({
          url: '/activities',
          query: {
            sort: '-created_at',
            all_pages: null,
          },
        }),
        { accessToken }
      )
    }
  )

  return {
    isLoading,
    activities: data?.activities,
  }
}

export default function ActivitiesView() {
  const { isLoading, activities } = useActivities()

  if (isLoading) {
    return null
  }

  return <ActivitiesList activities={activities} />
}
