import { useMutation, useQueryCache } from 'react-query'
import api from 'services/api'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'

const NOOP = () => {}

export const useCreateProjectStory = ({ onDone = NOOP }) => {
  const queryCache = useQueryCache()
  const methods = useForm({
    defaultValues: {
      tags: [],
    },
  })

  const { projectId } = useParams()
  const [user] = useAuthState(getAuth())

  const [createStory] = useMutation(
    async ({
      title,
      notes,
      dueOn,
      publishedOn,
      metaTitle,
      metaDescription,
      attachments,
      status,
    }) => {
      const accessToken = await user.getIdToken()

      const data = {
        article: {
          title,
          notes,
          due_on: dueOn?.toISOString(),
          published_on: publishedOn?.toISOString(),
          meta_title: metaTitle,
          meta_description: metaDescription,
          enclosed_files: attachments ? attachments.map((a) => a.value) : [],
          project_id: projectId,
          status: status?.value,
        },
      }

      return api.fetch(`/articles`, {
        method: 'POST',
        body: JSON.stringify(data),
        accessToken,
      })
    },
    {
      onSuccess: (data) => {
        queryCache.setQueryData(['stories', data.article.id], data)
        queryCache.invalidateQueries('stories')

        onDone()
      },
      onError: (error) => {
        if (error.errorCode !== 'resource_invalid') {
          throw error
        }

        const fieldErrors = error?.details?.field_errors
        methods.setError(
          fieldErrors.map(({ field, code }) => ({
            name: field,
            message: code,
          }))
        )
      },
    }
  )

  const handleSubmit = methods.handleSubmit(createStory)

  return { onSubmit: handleSubmit, methods }
}
