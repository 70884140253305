import React from 'react'
import { Link } from 'react-router-dom'

const LoggedOutView = () => {
  return (
    <div className="pt-32">
      <div className="lg:w-1/3 w-3/4 h-32 bg-white mx-auto p-8 border border-outline flex items-center justify-center">
        <div className="text-center">
          You are now logged out.
          <br />
          <Link to="/login" className="text-primary">
            Log back in.
          </Link>
        </div>
      </div>
    </div>
  )
}

export default LoggedOutView
