const getEvent = (story, dateAttribute) => {
  const defaultClassName = 'text-blue hover:text-blue bg-blue-light'
  const eventClassName = story.project?.color
    ? `
          text-p-${story.project.color}
          bg-p-${story.project.color}-light
          border-p-${story.project.color}
          hover:text-p-${story.project.color}
        `
    : defaultClassName

  return {
    id: story.id,
    title: story.title,
    date: story[dateAttribute],
    classNames: [
      'border-t-0 border-b-0 border-r-0 rounded-none py-3 px-2 border-l-2 mx-0',
      eventClassName,
    ],
    editable: story.permissions.update,
  }
}

export const getEvents = (stories, filter) => {
  if (!stories) {
    return []
  }

  let events = stories
    ? stories.map((story) => getEvent(story, 'published_at'))
    : []

  if (filter.showDueDate) {
    events = events.concat(
      stories.map((story) => ({
        ...getEvent(story, 'due_at'),
        isDueDate: true,
      }))
    )
  }

  return events
}
