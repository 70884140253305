import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import StoriesList from './stories-list'
import { useQuery } from 'react-query'
import api from 'services/api'
import qs from 'query-string'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'

const useStoriesDashboard = () => {
  const [user] = useAuthState(getAuth())

  const queryParams = {
    active: null,
    sort: '+due_at,+published_at,-updated_at',
    per_page: 10,
  }

  const { data, isLoading } = useQuery(['articles', queryParams], async () => {
    const accessToken = await user.getIdToken()

    return api.fetch(
      qs.stringifyUrl({
        url: '/articles',
        query: queryParams,
      }),
      { accessToken }
    )
  })

  return {
    isLoading,
    stories: data?.articles,
  }
}

export default function StoriesDashboard() {
  const { isLoading, stories } = useStoriesDashboard()

  return (
    <div>
      <div className="text-silver mb-6 flex justify-between items-center">
        <span className="text-xl">
          <FormattedMessage
            id="dashboard.stories.title"
            defaultMessage="Your stories"
          />
        </span>

        <Link to="/stories" className="uppercase text-sm">
          <FormattedMessage
            id="dashboard.stories.all"
            defaultMessage="All stories"
          />
        </Link>
      </div>

      <div className="md:bg-white min-h-24 md:px-6 md:mx-0 py-2">
        <StoriesList loading={isLoading} stories={stories} />
      </div>
    </div>
  )
}
