import CalendarPanel from 'domains/calendar/panel'
import React from 'react'
import { useCalendarPanel } from './hooks'

export default function ({ filter, onProjectClick, onDisplayOptionClick }) {
  const { isLoading, projects } = useCalendarPanel()

  if (isLoading) {
    return null
  }

  return (
    <CalendarPanel
      filter={filter}
      projects={projects}
      onProjectClick={onProjectClick}
      onDisplayOptionClick={onDisplayOptionClick}
    />
  )
}
