import { useQuery } from 'react-query'
import api from 'services/api'
import qs from 'query-string'
import { useParams } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'

export const useProjectCalendar = () => {
  const { projectId } = useParams()
  const [user] = useAuthState(getAuth())

  const { isLoading, data } = useQuery(
    ['stories', { active: true, projectId }],
    async () => {
      const accessToken = await user.getIdToken()
      const url = qs.stringifyUrl(
        {
          url: '/articles',
          query: {
            active: null,
            project_ids: [projectId],
          },
        },
        {
          arrayFormat: 'bracket',
        }
      )

      return api.fetch(url, { accessToken })
    }
  )

  return {
    isLoading,
    stories: data?.articles,
  }
}
