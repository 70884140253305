import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import frLocale from '@fullcalendar/core/locales/fr'
import { useIntl } from 'react-intl'

export default function MonthCalendar({
  events,
  onNewEventClick,
  onEventDrop,
  onEventClick,
}) {
  const intl = useIntl()

  return (
    <FullCalendar
      defaultView="dayGridMonth"
      plugins={[dayGridPlugin, interactionPlugin]}
      events={events}
      eventStartEditable
      height="parent"
      customButtons={{
        myCustomButton: {
          text: intl.formatMessage({
            id: 'stories.calendar.newStory',
            defaultMessage: 'New story',
          }),
          click: onNewEventClick,
        },
      }}
      buttonText={{
        today: intl.formatMessage({
          id: 'stories.calendar.today',
          defaultMessage: 'Today',
        }),
        month: intl.formatMessage({
          id: 'stories.calendar.month',
          defaultMessage: 'Month',
        }),
        week: intl.formatMessage({
          id: 'stories.calendar.week',
          defaultMessage: 'Week',
        }),
      }}
      header={{
        left: 'dayGridMonth,dayGridWeek',
        center: 'title',
        right: 'today myCustomButton prev,next',
      }}
      eventDrop={onEventDrop}
      timeZone="local"
      locales={[frLocale]}
      locale="fr"
      eventClick={onEventClick}
      eventTimeFormat={{
        hour: '2-digit',
        minute: '2-digit',
        omitZeroMinute: false,
        meridiem: 'narrow',
      }}
      eventRender={({ el, event }) => {
        if (event.extendedProps.isDueDate) {
          const time = el.querySelector('.fc-time')
          time.innerHTML = `🔔 ${time.innerHTML}`
        }
      }}
    />
  )
}
