import React from 'react'
import { useIntl } from 'react-intl'
import { ReactComponent as Illustration } from './create-project.svg'
import CreateProjectForm from './form'
import { withIntl } from 'services/intl'

const CreateProjectView = ({ onSubmit, submitting, error }) => {
  const intl = useIntl()
  const errorMessage = error
    ? intl.formatMessage({
        id: `stories.errors.${error.errorCode}`,
      })
    : null
  const fieldErrors = error ? error.details.field_errors : []

  return (
    <div className="py-20">
      <Illustration
        className="w-full md:w-1/2 xl:w-1/4 mb-10 mx-auto"
        style={{ height: 250 }}
      />

      <CreateProjectForm
        disabled={submitting}
        error={errorMessage}
        fieldErrors={fieldErrors}
        onSubmit={onSubmit}
      />
    </div>
  )
}

export default withIntl(require('./translations.json'))(CreateProjectView)
