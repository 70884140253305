import React from 'react'
import StoryDetails from 'pages/stories/details'
import { withIntl } from 'services/intl'

const StoryView = () => {
  return <StoryDetails />
}

export default withIntl(require('./details/details.translations.json'))(
  StoryView
)
