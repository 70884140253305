import React from 'react'
import Project from './project'

export default function ProjectsList({ projects }) {
  return (
    <div className="-m-4 md:flex flex-wrap">
      {projects.map((project) => (
        <div className="p-4 md:w-1/4" key={project.id}>
          <Project
            name={project.name}
            id={project.id}
            storiesCount={project.articles_count}
            color={project.color}
            accounts={project.accounts}
            owner={project.account}
          />
        </div>
      ))}
    </div>
  )
}
