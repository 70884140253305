import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { createIntl, FormattedMessage } from 'react-intl'
import cx from 'classnames'
import ErrorMessage from 'components/error-message'
import Select from 'components/select'
import { withIntl } from 'services/intl'

export const useMemberForm = () => {
  const methods = useFormContext()
  const intl = createIntl({
    locale: 'fr',
    messages: require('./translations').fr,
  })

  const accessOptions = [
    {
      value: 'viewer',
      label: intl.formatMessage({
        id: 'members.access.viewer',
      }),
    },
    {
      value: 'editor',
      label: intl.formatMessage({
        id: 'members.access.editor',
      }),
    },
  ]

  const accessOptionsLabelsDict = accessOptions.reduce(
    (acc, t) => ({
      ...acc,
      [t.value]: t.label,
    }),
    {}
  )

  return {
    methods,
    intl,
    accessOptions,
    accessOptionsLabelsDict,
  }
}

function MemberForm({ onSubmit, onCancel }) {
  const { methods, intl, accessOptions } = useMemberForm()
  const { errors, control, formState } = methods

  return (
    <form onSubmit={onSubmit}>
      <div className="md:flex">
        <div className="mb-6 md:mb-0 md:mr-4 flex-1">
          <input
            name="account.email"
            className={cx('border w-full rounded font-medium p-4 mb-1', {
              'border-pink': !!errors.account,
              'border-outline': !errors.account,
            })}
            placeholder={intl.formatMessage({
              id: 'members.email.placeholder',
            })}
            ref={methods.register({
              required: 'required',
            })}
          />

          <ErrorMessage errors={errors} name="account.email" />
        </div>

        <div className="mb-6 md:mb-0 md:w-64">
          <Controller
            as={Select}
            options={accessOptions}
            control={control}
            onChange={([selected]) => selected}
            name="name"
            placeholder={intl.formatMessage({
              id: 'members.role.placeholder',
            })}
            rules={{ required: true }}
          />

          <ErrorMessage errors={errors} name="name" />
        </div>

        <div className="flex justify-center items-center md:w-1/3 md:h-16">
          <button
            onClick={onCancel}
            className="p-4 w-1/3 md:w-64 rounded uppercase font-medium text-primary hover:text-primary-dark disabled:text-silver mr-2"
            disabled={formState.isSubmitting}
            type="button"
          >
            <FormattedMessage
              id="members.button.cancel"
              defaultMessage="Cancel"
            />
          </button>

          <button
            className="p-4 w-1/3 md:w-64 rounded uppercase font-medium bg-primary hover:bg-primary-dark text-white disabled:text-silver disabled:bg-background ml-2"
            disabled={formState.isSubmitting}
            type="submit"
          >
            <FormattedMessage id="members.button.submit" defaultMessage="Add" />
          </button>
        </div>
      </div>
    </form>
  )
}

export default withIntl(require('./translations.json'))(MemberForm)
