import React from 'react'
import cx from 'classnames'

export default function Checkbox({ children, checked, ...props }) {
  return (
    <label className="mb-1 relative cursor-pointer pl-8 flex items-center">
      <input
        {...props}
        type="checkbox"
        className="absolute opacity-0 w-0 h-0"
        checked={checked}
      />

      <span
        className={cx('absolute top-0 left-0 w-6 h-6 rounded', {
          'bg-outline hover:bg-gray': !checked,
          'bg-primary hover:bg-primary-dark': checked,
        })}
      ></span>

      <span
        className={cx('absolute', { block: checked, hidden: !checked })}
        style={{
          left: 7,
          top: 3,
          width: 7,
          height: 12,
          border: 'solid white',
          WebkitTransform: 'rotate(45deg)',
          msTransform: 'rotate(45deg)',
          borderWidth: '0 3px 3px 0',
          transform: 'rotate(45deg)',
        }}
      ></span>

      {children}
    </label>
  )
}
