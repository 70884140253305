import React, { useState, createContext, useContext, useRef } from 'react'

const Context = createContext()

export const useHead = () => useContext(Context)
export const HeadProvider = ({ children }) => {
  const [headState, setHeadState] = useState({})
  const { current: setTitle } = useRef((title) => {
    setHeadState((state) => ({
      ...state,
      title,
    }))
  })

  return (
    <Context.Provider
      value={{
        head: headState,
        setTitle,
      }}
    >
      {children}
    </Context.Provider>
  )
}
