import api from 'services/api'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryCache } from 'react-query'
import qs from 'query-string'
import { useForm } from 'react-hook-form'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'

const useStoryDetails = () => {
  const methods = useForm({
    defaultValues: {
      tags: [],
    },
  })

  const { storyId } = useParams()
  const queryCache = useQueryCache()
  const [user] = useAuthState(getAuth())

  const { isLoading, data: storyData } = useQuery(
    ['stories', storyId],
    async () => {
      const accessToken = await user.getIdToken()
      return api.fetch(`/articles/${storyId}`, { accessToken })
    },
    {
      onSuccess: ({ article }) =>
        methods.reset({
          title: article.title,
          notes: article.notes,
          dueOn: article.due_on && new Date(article.due_on),
          publishedOn: article.published_on && new Date(article.published_on),
          project: article.project && {
            color: article.project.color,
            label: article.project.name,
            value: article.project.id,
          },
          status: {
            value: article.status,
          },
          tags: article.tags,
        }),
    }
  )

  const { data: projectsData } = useQuery(
    ['projects', { active: true, sort: '+name', account: true }],
    async () => {
      const accessToken = await user.getIdToken()

      const { account } = await api.fetch('/account', { accessToken })

      return api.fetch(
        qs.stringifyUrl({
          url: '/projects',
          query: {
            active: null,
            sort: '+name',
            account_id: account.id,
          },
        }),
        { accessToken }
      )
    }
  )

  const [updateStory] = useMutation(
    async ({
      title,
      notes,
      metaDescription,
      attachments,
      metaTitle,
      dueOn,
      publishedOn,
      project,
      tags,
      status,
    }) => {
      const data = {
        article: {
          title,
          notes,
          due_on: dueOn?.toISOString(),
          published_on: publishedOn?.toISOString(),
          meta_title: metaTitle,
          meta_description: metaDescription,
          enclosed_files: attachments ? attachments.map((a) => a.value) : [],
          project_id: project?.value,
          tags,
          status: status?.value,
        },
      }

      const accessToken = await user.getIdToken()

      return api.fetch(`/articles/${storyId}`, {
        method: 'PUT',
        body: JSON.stringify(data),
        accessToken,
      })
    },
    {
      onSuccess: (data) => queryCache.setQueryData(['stories', storyId], data),
      onError: (error) => {
        if (error.errorCode !== 'resource_invalid') {
          throw error
        }

        const fieldErrors = error?.details?.field_errors
        fieldErrors.forEach(({ field, code }) => methods.setError(field, code))
      },
    }
  )

  const handleSubmit = methods.handleSubmit(updateStory)

  return {
    isLoading,
    methods,
    onSubmit: handleSubmit,
    storyId,
    projects: projectsData?.projects,
    story: storyData?.article,
  }
}

export default useStoryDetails
