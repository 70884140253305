import { useCallback, useReducer, useEffect } from 'react'
import { useModal } from 'components/modal'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'query-string'
import { useMutation, useQuery, useQueryCache } from 'react-query'
import api from 'services/api'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'

const reducer = (state, action) => {
  switch (action.type) {
    case 'SELECT_EVENT':
      return {
        ...state,
        eventId: action.eventId,
      }

    case 'UNSELECT_EVENT':
      return {
        ...state,
        eventId: null,
      }

    case 'SET_FILTER':
      return {
        ...state,
        projectIds: action.filter.projectIds,
        showDueDate: action.filter.showDueDate,
      }

    case 'SET_PROJECT_IDS':
      return {
        ...state,
        projectIds: action.projectIds,
      }

    case 'ENABLE_OPTION':
      return {
        ...state,
        [action.name]: true,
      }

    case 'DISABLE_OPTION':
      return {
        ...state,
        [action.name]: false,
      }

    default:
      return state
  }
}

const useCalendar = () => {
  const { isModalOpen, openModal, closeModal } = useModal()
  const location = useLocation()
  const [uiState, updateUiState] = useReducer(reducer, {
    projectIds: [],
    showDueDate: false,
  })
  const history = useHistory()
  const settingsModal = useModal()
  const queryCache = useQueryCache()
  const [user] = useAuthState(getAuth())

  useQuery(
    ['account'],
    async () => {
      const accessToken = await user.getIdToken()
      return api.fetch('/account', { accessToken })
    },
    {
      onSuccess: ({ account }) => {
        if (account.settings.include_due_dates_in_calendar) {
          updateUiState({ type: 'ENABLE_OPTION', name: 'showDueDate' })
        }
      },
    }
  )

  const [updateStoryDate] = useMutation(async ({ event, onError }) => {
    const accessToken = await user.getIdToken()

    const eventAtAttribute = event.extendedProps.isDueDate
      ? 'due_at'
      : 'published_at'

    const storyData = {
      article: {
        [eventAtAttribute]: event.start,
      },
    }

    return api.fetch(
      `/articles/${event.id}`,
      {
        method: 'PUT',
        body: JSON.stringify(storyData),
        accessToken,
      },
      {
        onSuccess: (data) =>
          queryCache.setQueryData(['stories', data.article.id], data),
        onError,
      }
    )
  })

  const handleEventClick = useCallback(
    ({ event }) => {
      updateUiState({ type: 'SELECT_EVENT', eventId: event.id })
      openModal()
    },
    [openModal]
  )

  const handleNewEventClick = useCallback(() => {
    updateUiState({ type: 'UNSELECT_EVENT' })
    openModal()
  }, [openModal])

  const handleProjectClick = useCallback(
    (e) => {
      let projectIds = uiState.projectIds

      if (e.target.checked) {
        projectIds.push(e.target.value)
      } else {
        const projectIdIndex = projectIds.findIndex(
          (projectId) => projectId === e.target.value
        )

        if (projectIdIndex < 0) {
          return
        }

        projectIds = [
          ...projectIds.slice(0, projectIdIndex),
          ...projectIds.slice(projectIdIndex + 1),
        ]
      }

      history.push({
        search: qs.stringify({
          ...qs.parse(location.search),
          project_ids: projectIds,
        }),
      })

      updateUiState({ type: 'SET_PROJECT_IDS', projectIds })
    },
    [history, location.search, uiState.projectIds]
  )

  const handleDisplayOptionClick = (e) => {
    if (e.target.checked) {
      updateUiState({
        type: 'ENABLE_OPTION',
        name: e.target.name,
      })

      toggleUserPreferences(e.target.name, true)

      return
    }

    updateUiState({
      type: 'DISABLE_OPTION',
      name: e.target.name,
    })

    toggleUserPreferences(e.target.name, false)
  }

  const toggleUserPreferences = (preferenceName, active) => {
    if (preferenceName === 'showDueDate') {
      return api.fetch('/account', {
        method: 'PUT',
        body: JSON.stringify({
          account: {
            settings: {
              include_due_dates_in_calendar: active,
            },
          },
        }),
      })
    }
  }

  useEffect(() => {
    const { project_ids: projectIds } = qs.parse(location.search)

    updateUiState({
      type: 'SET_PROJECT_IDS',
      projectIds: (Array.isArray(projectIds)
        ? projectIds
        : [projectIds]
      ).filter((v) => v),
    })
  }, [location.search])

  const filter = {
    projectIds: uiState.projectIds,
    showDueDate: uiState.showDueDate,
  }

  return {
    isModalOpen,
    openModal,
    closeModal,
    handleEventDrop: updateStoryDate,
    handleEventClick,
    handleNewEventClick,
    selected: uiState.eventId,
    handleProjectClick,
    handleDisplayOptionClick,
    filter,
    settingsModal,
  }
}

export { useCalendar }
export default useCalendar
