import { useState } from 'react'

export const useModal = () => {
  const [isOpen, setIsOpen] = useState(false)

  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)

  return {
    isModalOpen: isOpen,
    openModal: open,
    closeModal: close,
  }
}

export default useModal
