import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import Project from './project'

const ProjectsList = ({ loading, projects }) => {
  if (loading) {
    return null
  }

  if (!projects.length) {
    return (
      <div className="h-24 md:h-48 justify-center flex items-center bg-white">
        <div>
          <FormattedMessage
            id="dashboard.projects.empty"
            defaultMessage="You haven't started any project yet."
          />

          <Link
            to="/projects/create"
            className="text-primary block text-center"
          >
            <FormattedMessage
              id="dashboard.projects.cta"
              defaultMessage="Create one"
            />
          </Link>
        </div>
      </div>
    )
  }

  return (
    <div className="md:flex md:-mx-4 flex-wrap">
      {projects.map((project) => (
        <div key={project.id} className="md:w-1/2 md:px-4">
          <Project
            id={project.id}
            name={project.name}
            storiesCount={project.articles_count}
          />
        </div>
      ))}
    </div>
  )
}

export default ProjectsList
