import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

export default function useStoryForm() {
  const intl = useIntl()
  const { watch, errors, control } = useFormContext()

  const placeholders = {
    title: intl.formatMessage({
      id: 'stories.form.title.placeholder',
      defaultMessage: "What's your story?",
    }),
    notes: intl.formatMessage({
      id: 'stories.form.notes.placeholder',
      defaultMessage: 'Add some notes your story',
    }),
    tags: intl.formatMessage({
      id: 'stories.form.tags.placeholder',
      defaultMessage: 'Add some tags your story',
    }),
    dueOn: intl.formatMessage({
      id: 'stories.form.dueOn.placeholder',
      defaultMessage: 'Set a deadline',
    }),
    publishedOn: intl.formatMessage({
      id: 'stories.form.publishedOn.placeholder',
      defaultMessage: 'Set a publication date',
    }),
    projectId: intl.formatMessage({
      id: 'stories.form.projectId.placeholder',
      defaultMessage: 'Select a project',
    }),
    noProject: intl.formatMessage({
      id: 'stories.form.projectId.none',
      defaultMessage: 'None',
    }),
    addTag: intl.formatMessage({
      id: 'stories.form.tags.add',
      defaultMessage: 'Add tag',
    }),
    status: intl.formatMessage({
      id: 'stories.form.status.placeholder',
      defaultMessage: 'Set a status',
    }),
  }

  return {
    watch,
    errors,
    control,
    intl,
    placeholders,
  }
}
