import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import qs from 'query-string'
import cx from 'classnames'

const PaginationLink = ({ children, to, current, className }) => {
  const linkClassName = cx(
    'w-10 block text-center h-10 leading-10 rounded',
    className,
    {
      'text-white bg-primary': current,
    }
  )

  return (
    <Link to={to} className={linkClassName}>
      {children}
    </Link>
  )
}

export default function Pagination({
  currentPage,
  previousPage,
  nextPage,
  totalPages,
}) {
  const pages = new Array(totalPages).fill(null).map((_, index) => index + 1)
  const location = useLocation()

  const getPageTo = (page) => {
    return {
      ...location,
      search: qs.stringify({
        ...qs.parse(location.search),
        page,
      }),
    }
  }

  return (
    <div className="flex justify-center">
      {previousPage ? (
        <PaginationLink
          to={getPageTo(previousPage)}
          current={false}
          className="mr-4"
        >
          &#171;
        </PaginationLink>
      ) : (
        <span className="w-10 block mr-4">&nbsp;</span>
      )}

      {pages.map((page) => (
        <PaginationLink
          key={page}
          to={getPageTo(page)}
          current={currentPage === page}
        >
          {page}
        </PaginationLink>
      ))}

      {nextPage ? (
        <PaginationLink
          to={getPageTo(nextPage)}
          current={false}
          className="ml-4"
        >
          &#187;
        </PaginationLink>
      ) : (
        <span className="w-10 block ml-4">&nbsp;</span>
      )}
    </div>
  )
}
