import React from 'react'
import { FormattedMessage } from 'react-intl'
import api from 'services/api'
import ActivitiesList from 'domains/activities/list'
import { withIntl } from 'services/intl'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import qs from 'query-string'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'

const useActivitiesDashboard = () => {
  const queryParams = { sort: '-created_at', per_page: 10 }
  const [user] = useAuthState(getAuth())

  const { data, isLoading } = useQuery(
    ['activities', queryParams],
    async () => {
      const accessToken = await user.getIdToken()

      return api.fetch(
        qs.stringifyUrl({
          url: '/activities',
          query: queryParams,
        }),
        { accessToken }
      )
    }
  )

  return {
    isLoading,
    activities: data?.activities,
  }
}

const ActivityDashboard = () => {
  const { isLoading, activities } = useActivitiesDashboard()

  return (
    <div className="h-full">
      <div className="flex justify-between text-silver mb-6 text-xl items-center">
        <FormattedMessage
          id="dashboard.activities.title"
          defaultMessage="Your activity"
        />

        <Link to="/activities" className="uppercase text-sm">
          <FormattedMessage
            id="dashboard.activities.showAll"
            defaultMessage="Show All"
          />
        </Link>
      </div>

      {!isLoading &&
        activities &&
        (!activities.length > 0 ? (
          <div className="justify-center flex items-center bg-white h-full text-center p-4">
            <FormattedMessage
              id="dashboard.activities.placeholder"
              defaultMessage="Your recent activity will show up here."
            />
          </div>
        ) : (
          <ActivitiesList activities={activities} />
        ))}
    </div>
  )
}

export default withIntl(require('./translations.json'))(ActivityDashboard)
