import React from 'react'
import ActivityListItem from './item'
import { withIntl } from 'services/intl'

const ActivitiesList = ({ activities }) => {
  return (
    <div>
      {activities.map((activity) => (
        <ActivityListItem
          id={activity.id}
          name={activity.key}
          createdAt={activity.created_at}
          ownerType={activity.owner_type}
          owner={activity.owner}
          objectType={activity.trackable_type}
          object={activity.trackable}
          objectName={activity.trackable_name}
          key={activity.id}
        />
      ))}
    </div>
  )
}

export default withIntl(require('./translations.json'))(ActivitiesList)
