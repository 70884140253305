import React from 'react'
import cx from 'classnames'
import ReactTooltip from 'react-tooltip'

export default function AvatarsStack({
  maxCount,
  avatars,
  className,
  avatarClassName,
  tooltipId = 'tooltip',
  stacked = true,
}) {
  if (!avatars.length) {
    return null
  }

  const avatarsToShow = maxCount ? avatars.slice(0, maxCount - 1) : avatars
  const avatarsToShowMap = avatarsToShow.reduce(
    (acc, avatar) => ({
      ...acc,
      [avatar.id]: avatar,
    }),
    {}
  )

  return (
    <div
      className={cx('inline-flex', className)}
      data-tip=""
      data-for={tooltipId}
    >
      {avatarsToShow.map((avatar) => (
        <img
          key={avatar.id}
          src={avatar.pictureUrl}
          alt={avatar.name}
          className={cx('rounded-full', avatarClassName, {
            '-ml-4': stacked,
          })}
          data-for={`${tooltipId}-user`}
          data-tip={avatar.id}
        />
      ))}

      <ReactTooltip
        type="dark"
        effect="solid"
        className="bg-black"
        getContent={(dataTip) => dataTip && avatarsToShowMap[dataTip]?.name}
        id={`${tooltipId}-user`}
        disable={avatarsToShow.length < avatars.length}
      />

      {avatarsToShow.length < avatars.length && (
        <>
          <span
            className={cx(
              'rounded-full bg-background text-gray flex items-center justify-center',
              avatarClassName,
              {
                '-ml-4': stacked,
              }
            )}
          >
            +{avatars.length - avatarsToShow.length}
          </span>

          <ReactTooltip
            type="dark"
            effect="solid"
            className="bg-black"
            id={tooltipId}
          >
            {avatars.map((avatar) => (
              <span key={avatar.id} className="block">
                {avatar.name}
              </span>
            ))}
          </ReactTooltip>
        </>
      )}
    </div>
  )
}
