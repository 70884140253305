import React from 'react'
import Select from 'react-select'

const customStyles = {
  singleValue: (provided) => ({
    ...provided,
    fontSize: '0.875rem',
    textTransform: 'uppercase',
    fontWeight: 500,
  }),
  control: (provided, { state }) => ({
    ...provided,
    background: 'none',
    border: 'none',
    boxShadow: 'none',
    '&:active': {
      border: 'none',
      boxShadow: 'none',
    },
    '&:focus': {
      border: 'none',
      boxShadow: 'none',
    },
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    margin: 0,
  }),
  menu: (styles, { isFocused }) => ({
    ...styles,
    border: 'none',
    zIndex: 30,
  }),
  option: (styles) => ({
    ...styles,
    background: 'none',
    color: '#1B1E24',
    fontWeight: 500,
    '&:hover': {
      color: '#4D7CFE',
      cursor: 'pointer',
    },
  }),
}

export default function FilterDropdown(props) {
  return (
    <Select
      {...props}
      styles={customStyles}
      components={{
        IndicatorSeparator: null,
      }}
      isSearchable={false}
    />
  )
}
