import React, { useRef } from 'react'
import { FormattedMessage, FormattedDate } from 'react-intl'
import ProjectTag from 'domains/projects/tag'
import InlineEdit from 'components/inline-edit/inline-edit'
import { Controller } from 'react-hook-form'
import TextareaAutosize from 'react-textarea-autosize'
import DatePicker from 'react-datepicker'
import ErrorMessage from 'components/error-message'
import { withIntl } from 'services/intl'
import { components } from 'react-select'
import InlineSelect from 'components/inline-select'
import TagsInput from 'react-tagsinput'
import { renderTag } from 'components/tags-input/tags-Input'
import TagsList from 'components/tags-list'
import StatusSelect from './status-select'
import useStoryForm from './use-form'

const ColorOption = ({ label, ...props }) => {
  return (
    <components.Option {...props} label={label}>
      <span className={`text-p-${props.data.color}`}>{label}</span>
    </components.Option>
  )
}

const ColorValue = ({ hasValue, ...props }) => {
  if (!hasValue) {
    return <components.SingleValue {...props} hasValue={hasValue} />
  }

  return (
    <components.SingleValue {...props} hasValue={hasValue}>
      <span className={`text-p-${props.data.color}`}>{props.data.label}</span>
    </components.SingleValue>
  )
}

const StorySummaryForm = ({ onBlur, projects, unavailableFields = [] }) => {
  const { placeholders, watch, errors, control } = useStoryForm()
  const fields = watch()

  const projectOptions = projects
    ? projects.map(({ id, name, color }) => ({
        value: id,
        label: name,
        color,
      }))
    : []

  const inputTagRef = useRef()
  const handleTagsListFocus = () => inputTagRef.current.focus()
  const hasProject = !!fields.project

  return (
    <div className="">
      <div className="text-2xl mb-6">
        <InlineEdit>
          <>
            {fields.title || (
              <span className="text-gray">{placeholders.title}</span>
            )}
          </>

          <>
            <Controller
              as={<TextareaAutosize onBlur={onBlur} />}
              className="block w-full h-full resize-none focus:outline-none"
              name="title"
              placeholder={placeholders.title}
              control={control}
              rules={{ required: 'required' }}
            />
          </>
        </InlineEdit>

        <ErrorMessage errors={errors} name="title" />
      </div>

      <div className="flex justify-between items-center mb-6">
        {!unavailableFields['project'] && (
          <div className="w-1/2 md:w-1/3">
            <div className="mb-1">
              <FormattedMessage
                id="stories.form.projectId"
                defaultMessage="Project"
              />
            </div>

            <InlineEdit
              wrapperClassName="inline-block"
              wrapperActiveClassName="w-full"
              onBlur={onBlur}
            >
              <>
                {hasProject && (
                  <ProjectTag
                    name={fields.project.label}
                    color={fields.project.color}
                  />
                )}

                {!hasProject && (
                  <span className="text-gray">{placeholders.projectId}</span>
                )}
              </>

              <>
                <Controller
                  as={InlineSelect}
                  className="block h-full border-0"
                  name="project"
                  placeholder={placeholders.projectId}
                  control={control}
                  options={projectOptions}
                  closeMenuOnSelect
                  blurInputOnSelect
                  openMenuOnFocus
                  isClearable
                  components={{
                    Option: ColorOption,
                    SingleValue: ColorValue,
                  }}
                  onChange={([selected]) => selected}
                />
              </>
            </InlineEdit>
          </div>
        )}

        {!unavailableFields['status'] && (
          <StatusSelect
            onBlur={onBlur}
            value={fields.status?.value}
            placeholder={placeholders.status}
          />
        )}
      </div>

      <div className="flex justify-between items-center mb-6">
        <div className="w-1/2 md:w-1/3">
          <div className="mb-1">
            <FormattedMessage
              id="stories.form.dueOn"
              defaultMessage="Deadline"
            />
          </div>

          <InlineEdit onBlur={onBlur}>
            <>
              {fields.dueOn && (
                <span>
                  <FormattedDate
                    value={new Date(fields.dueOn)}
                    year="numeric"
                    month="short"
                    day="2-digit"
                  />
                </span>
              )}

              {!fields.dueOn && (
                <span className="text-gray">{placeholders.dueOn}</span>
              )}
            </>

            <>
              <Controller
                as={DatePicker}
                name="dueOn"
                control={control}
                dateFormat="dd MMM yyyy"
                valueName="selected"
                className="block w-full h-full resize-none focus:outline-none"
                shouldCloseOnSelect
                showTimeSelect
              />
            </>
          </InlineEdit>

          <ErrorMessage errors={errors} name="dueOn" />
        </div>

        <div className="w-1/2 md:w-1/3">
          <div className="mb-1">
            <FormattedMessage
              id="stories.form.publishedOn"
              defaultMessage="Publication date"
            />
          </div>

          <InlineEdit onBlur={onBlur}>
            <>
              {fields.publishedOn && (
                <FormattedDate
                  value={new Date(fields.publishedOn)}
                  year="numeric"
                  month="short"
                  day="2-digit"
                />
              )}

              {!fields.publishedOn && (
                <span className="text-gray">{placeholders.publishedOn}</span>
              )}
            </>

            <>
              <Controller
                as={DatePicker}
                name="publishedOn"
                control={control}
                dateFormat="dd MMM yyyy"
                valueName="selected"
                className="block w-full h-full resize-none focus:outline-none"
                shouldCloseOnSelect
                showTimeSelect
              />
            </>
          </InlineEdit>

          <ErrorMessage errors={errors} name="publishedOn" />
        </div>
      </div>

      <div className="mb-6">
        <div className="mb-1">
          <FormattedMessage
            id="stories.form.notes"
            defaultMessage="Description"
          />
        </div>

        <InlineEdit onBlur={onBlur}>
          <div className="whitespace-pre-line">
            {fields.notes || (
              <span className="text-gray">{placeholders.notes}</span>
            )}
          </div>

          <>
            <Controller
              as={TextareaAutosize}
              className="block w-full h-full resize-none focus:outline-none"
              name="notes"
              placeholder={placeholders.notes}
              control={control}
            />
          </>
        </InlineEdit>

        <ErrorMessage errors={errors} name="notes" />
      </div>

      <div className="mb-6">
        <div className="mb-1">
          <FormattedMessage id="stories.form.tags" defaultMessage="Tags" />
        </div>

        <InlineEdit
          onBlur={onBlur}
          onFocus={handleTagsListFocus}
          overrideOnFocus
        >
          {fields.tags && fields.tags.length > 0 ? (
            <TagsList tags={fields.tags} />
          ) : (
            <span className="text-gray">{placeholders.tags}</span>
          )}

          <Controller
            as={TagsInput}
            className="block w-full h-full resize-none focus:outline-none"
            name="tags"
            control={control}
            inputProps={{
              placeholder: placeholders.addTag,
              className: 'border-none focus:border-none focus:outline-none',
              ref: inputTagRef,
            }}
            renderTag={renderTag}
          />
        </InlineEdit>

        <ErrorMessage errors={errors} name="tags" />
      </div>
    </div>
  )
}

export default withIntl(require('./translations.json'))(StorySummaryForm)
