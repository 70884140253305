import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useMutation, useQuery, useQueryCache } from 'react-query'
import { Link, useParams } from 'react-router-dom'
import api from 'services/api'
import useAuthorizeContent from 'domains/stories/content/hooks/use-authorize'
import emptyFile from './unnamed.png'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'

const useStoryDetailsContent = () => {
  const { storyId } = useParams()
  const {
    accessToken: googleAccessToken,
    isAuthorized,
    isLoading: authorizationIsLoading,
    grantScope,
  } = useAuthorizeContent()
  const queryCache = useQueryCache()
  const [user] = useAuthState(getAuth())

  const userQuery = useQuery('account', async () => {
    const accessToken = await user.getIdToken()
    return api.fetch('/account', { accessToken })
  })

  const [updateAccountAccessToken] = useMutation(
    async ({ code }) => {
      const accessToken = await user.getIdToken()

      return api.fetch('/account/google_credentials', {
        accessToken,
        method: 'POST',
        body: JSON.stringify({
          code,
        }),
      })
    },
    {
      onSuccess: (data) => queryCache.setQueryData('account', data),
    }
  )

  const { isLoading: storyIsLoading, data: storyData } = useQuery(
    ['stories', storyId],
    async () => {
      const accessToken = await user.getIdToken()
      return api.fetch(`/articles/${storyId}`, { accessToken })
    }
  )

  const { isLoading, data } = useQuery(
    ['stories-details-content', storyId],
    async () => {
      const accessToken = await user.getIdToken()

      return api
        .fetch(`/articles/${storyId}`, { accessToken })
        .then(({ article }) => {
          if (article.content_id) {
            return api.fetch(`/articles/${storyId}/content`, {
              accessToken,
              headers: {
                'x-edito-g-access-token': googleAccessToken,
              },
            })
          }

          return null
        })
    },
    {
      enabled: googleAccessToken,
    }
  )

  const handleGrant = async () => {
    const response = await grantScope(userQuery.data.account.email)
    return updateAccountAccessToken({ code: response.code })
  }

  const hasAuthorizedAccess =
    isAuthorized && userQuery.data?.account.has_google_credentials

  return {
    isLoading: isLoading || storyIsLoading || userQuery.isLoading,
    content: data?.content,
    storyId,
    onGrantClick: handleGrant,
    hasAuthorizedAccess,
    authorizationIsLoading,
    story: storyData.article,
    userQuery,
  }
}

const Content = ({ storyId, thumbnailLink, title }) => {
  return (
    <>
      <img
        src={thumbnailLink}
        alt={title}
        className="mx-auto bg-white"
        style={{ width: 220 }}
      />

      <Link
        to={`/stories/${storyId}/content`}
        className="absolute flex justify-center items-center z-10 inset-0"
        target="_blank"
      >
        <span className="text-primary">
          <FormattedMessage
            id="stories.details.content.open"
            defaultMessage="Open"
          />
        </span>
      </Link>
    </>
  )
}

const EmptyContent = ({ storyId }) => {
  return (
    <>
      <img
        src={emptyFile}
        alt="Empty content"
        className="mx-auto bg-white"
        style={{ width: 220 }}
      />

      <Link
        to={`/stories/${storyId}/content`}
        className="absolute flex justify-center items-center z-10 inset-0"
        target="_blank"
      >
        <span className="text-primary">
          <FormattedMessage
            id="stories.details.content.open"
            defaultMessage="Open"
          />
        </span>
      </Link>
    </>
  )
}

const AuthorizeContent = ({ onClick }) => {
  return (
    <>
      <img
        src={emptyFile}
        alt="Empty content"
        className="mx-auto bg-white"
        style={{ width: 220 }}
      />

      <button onClick={onClick} className="w-full absolute inset-0 z-10">
        <FormattedMessage
          id="stories.details.content.authorize"
          defaultMessage="Edito needs access to Google Docs. Click here to authorize Edito to create new Google Docs."
        />
      </button>
    </>
  )
}

export default function StoryDetailsContent() {
  const {
    isLoading,
    content,
    storyId,
    hasAuthorizedAccess,
    onGrantClick,
    authorizationIsLoading,
    story,
  } = useStoryDetailsContent()

  return (
    <div>
      <div className="text-xl mb-6">
        <FormattedMessage
          id="stories.details.content.title"
          defaultMessage="Content"
        />
      </div>

      <div
        className="bg-background-light hover:bg-background hover:text-primary-dark relative text-center p-2"
        style={{ minHeight: 300 }}
      >
        {!isLoading && !authorizationIsLoading && (
          <>
            {story.permissions.owns ? (
              hasAuthorizedAccess ? (
                content ? (
                  <Content
                    storyId={storyId}
                    content={content}
                    thumbnailLink={content.thumbnail_link}
                  />
                ) : (
                  <EmptyContent storyId={storyId} />
                )
              ) : (
                <AuthorizeContent storyId={storyId} onClick={onGrantClick} />
              )
            ) : content ? (
              <Content
                storyId={storyId}
                content={content}
                thumbnailLink={content.thumbnail_link}
              />
            ) : (
              <EmptyContent storyId={storyId} />
            )}
          </>
        )}
      </div>
    </div>
  )
}
