import React from 'react'
import { PlusCircle } from 'react-feather'
import { FormattedMessage } from 'react-intl'
import EmptyStories from 'views/stories/empty/empty'
import { withIntl } from 'services/intl'
import translations from './list.translation.json'
import StoriesList from './list'
import { Link } from 'react-router-dom'
import StoryFilters from 'domains/stories/filters'
import ProjectsFilter from './projects-filter'
import { useQuery } from 'react-query'
import qs from 'query-string'
import useStoriesQueryString from 'domains/stories/filters/use-query-string'
import api from 'services/api'
import { usePagination } from 'components/pagination'
import Pagination from 'components/pagination'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'

const useStoriesList = () => {
  const [user] = useAuthState(getAuth())

  const storiesQueryString = useStoriesQueryString()
  const { page } = usePagination()
  const queryParams = {
    ...storiesQueryString,
    page,
    per_page: 10,
  }

  const { isLoading, data } = useQuery(['stories', queryParams], async () => {
    const accessToken = await user.getIdToken()

    const url = qs.stringifyUrl(
      {
        url: '/articles',
        query: queryParams,
      },
      {
        arrayFormat: 'bracket',
      }
    )

    return api.fetch(url, { accessToken })
  })

  return {
    isLoading,
    stories: data?.articles || [],
    pagination: data?.meta.pagination,
  }
}

const StoriesListView = () => {
  const { isLoading, stories, pagination } = useStoriesList()

  return (
    <div>
      <div className="flex mb-8 justify-between items-center">
        <div className="lg:flex items-center">
          <ProjectsFilter />

          <div className="lg:ml-2">
            <StoryFilters />
          </div>
        </div>

        <Link
          to="/stories/create"
          className="uppercase text-primary items-center"
        >
          <PlusCircle className="mr-1 inline-block" size={18} />
          <span className="font-medium text-sm">
            <FormattedMessage
              id="stories.list.new_story"
              defaultMessage="New story"
            />
          </span>
        </Link>
      </div>

      {!isLoading && !stories.length && <EmptyStories />}

      {stories.length > 0 && (
        <div className="-mx-2">
          <div className="px-2 lg:w-full">
            <StoriesList stories={stories} />
          </div>
        </div>
      )}

      {pagination && pagination.total_pages > 1 && (
        <div className="mt-8">
          <Pagination
            currentPage={pagination.current_page}
            previousPage={pagination.prev_page}
            nextPage={pagination.next_page}
            totalPages={pagination.total_pages}
          />
        </div>
      )}
    </div>
  )
}

export default withIntl(translations)(StoriesListView)
