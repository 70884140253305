import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import InlineEdit from 'components/inline-edit/inline-edit'
import { Controller, useFormContext } from 'react-hook-form'
import ErrorMessage from 'components/error-message'
import InlineSelect from 'components/inline-select'

const STATUS_OPTIONS = [
  {
    value: 'planned',
  },
  {
    value: 'draft',
  },
  {
    value: 'submitted',
  },
  {
    value: 'validated',
  },
]

export default function StatusSelect({ onBlur, value, placeholder }) {
  const intl = useIntl()
  const { errors, control } = useFormContext()

  const getStatusOptionLabel = ({ value }) =>
    intl.formatMessage({
      id: `stories.form.status.${value}`,
      defaultMessage: value,
    })

  const getLabelFromValue = (value) => {
    return (
      value &&
      intl.formatMessage({
        id: `stories.form.status.${value}`,
        defaultMessage: value,
      })
    )
  }

  return (
    <div className="w-1/2 md:w-1/3">
      <div className="mb-1">
        <FormattedMessage id="stories.form.status" defaultMessage="Status" />
      </div>

      <InlineEdit
        wrapperClassName="inline-block"
        wrapperActiveClassName="w-full"
        onBlur={onBlur}
      >
        <>
          {getLabelFromValue(value) || (
            <span className="text-gray">{placeholder}</span>
          )}
        </>

        <>
          <Controller
            as={InlineSelect}
            className="block h-full border-0"
            name="status"
            placeholder={placeholder}
            control={control}
            options={STATUS_OPTIONS}
            closeMenuOnSelect
            blurInputOnSelect
            openMenuOnFocus
            getOptionLabel={getStatusOptionLabel}
            onChange={([selected]) => selected}
          />
        </>
      </InlineEdit>

      <ErrorMessage errors={errors} name="status" />
    </div>
  )
}
