import React from 'react'
import ReactModal from 'react-modal'
import cx from 'classnames'

const modalRoot = document.getElementById('modal-root')
ReactModal.setAppElement(modalRoot)

export default function Modal(props) {
  return (
    <ReactModal
      {...props}
      parentSelector={() => modalRoot}
      overlayClassName="inset-0 fixed bg-dark z-10"
      className={cx('absolute', props.className)}
      bodyOpenClassName="overflow-hidden"
      htmlOpenClassName="overflow-hidden"
      style={{
        overlay: {
          backgroundColor: '#25263160',
        },
        content: {
          WebkitOverflowScrolling: 'touch',
        },
      }}
    />
  )
}
