import React from 'react'
import { FormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import ProjectSettingsForm from './form'
import useProjectSettings from './hooks'

const ProjectSettings = () => {
  const {
    project,
    methods,
    showSavedMessage,
    onSubmit,
    onArchive,
  } = useProjectSettings()

  const handleArchiveClick = () => onArchive(project)

  return (
    <div className="lg:w-1/2 xl:w-1/3 -mx-3 lg:mx-0">
      <div className="mb-6">
        <FormContext {...methods}>
          <ProjectSettingsForm
            onSubmit={onSubmit}
            showSavedMessage={showSavedMessage}
          />
        </FormContext>
      </div>

      <div className="p-6 bg-white">
        <div className="mb-6">
          <div className="mb-1 text-xl">
            <FormattedMessage
              id="projects.details.settings.archive"
              defaultMessage="Archive project"
            />
          </div>

          <div className="text-gray">
            {project.archived_at ? (
              <FormattedMessage
                id="projects.details.settings.archived.description"
                values={{ archivedAt: project.archived_at }}
              />
            ) : (
              <FormattedMessage id="projects.details.settings.archive.description" />
            )}
          </div>
        </div>

        <div>
          <button
            onClick={handleArchiveClick}
            className={`
              px-4 py-2
              border border-pink
              hover:border-pink
              hover:text-pink
              w-full rounded uppercase font-medium
            `}
          >
            {project.archived_at ? (
              <FormattedMessage
                id="projects.details.settings.unarchive"
                values={{ archivedAt: project.archived_at }}
              />
            ) : (
              <FormattedMessage
                id="projects.details.settings.archive"
                defaultMessage="Archive project"
              />
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProjectSettings
