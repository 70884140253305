import React from 'react'
import { FormContext } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import StorySummary from 'domains/stories/summary'
import { FormattedMessage } from 'react-intl'
import { useStoryDetails } from 'views/stories/details/hooks'
import StoryDetailsContent from 'views/stories/details/content'
import ShareStory from 'views/stories/details/share'
import ArchiveStory from 'views/stories/details/archive'
import { withIntl } from 'services/intl'
import StoryActionPanels from 'domains/stories/actions-panel/actions-panel'
import { ArrowLeftCircle } from 'react-feather'

const useProjectStoryDetails = () => {
  const { projectId } = useParams()

  return {
    ...useStoryDetails(),
    projectId,
  }
}

const ProjectStoryDetails = () => {
  const {
    methods,
    onSubmit,
    story,
    projects,
    storyId,
    projectId,
  } = useProjectStoryDetails()

  return (
    <div>
      {story && story.archived_at && (
        <div className="-mt-4 mb-8 text-pink">
          <FormattedMessage
            id="stories.details.archived"
            defaultMessage="This story is archived. Click on unarchive."
          />
        </div>
      )}

      <div className="xl:w-2/3 lg:flex">
        <div className="lg:w-2/3">
          <div className="border-b border-outline px-6 bg-white py-4">
            <Link to={`/projects/${projectId}/stories`} className="text-silver">
              <ArrowLeftCircle className="mr-1 inline-block" size={18} />
            </Link>
          </div>

          <FormContext {...methods}>
            <form onSubmit={onSubmit} className="bg-white p-6">
              {story && (
                <StorySummary
                  editable={story.permissions.update}
                  onBlur={onSubmit}
                  projects={projects}
                  story={story}
                  unavailableFields={{
                    project: true,
                  }}
                />
              )}
            </form>
          </FormContext>

          {story && (
            <>
              <div className="bg-white mt-8 p-6">
                <StoryDetailsContent />
              </div>

              {story.permissions.owns && (
                <>
                  <div className="bg-white mt-8 p-6">
                    <ShareStory accessToken={story.access_token} />
                  </div>

                  <div className="bg-white mt-8 p-6">
                    <ArchiveStory
                      archivedAt={story.archived_at}
                      storyId={story.id}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>

        {story && (
          <div className="p-6 hidden lg:block lg:w-1/3">
            <StoryActionPanels
              storyId={storyId}
              projectId={story.project?.id}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default withIntl(require('views/stories/details/details.translations'))(
  ProjectStoryDetails
)
