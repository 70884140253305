export const PROJECT_COLORS = {
  'dark-red': '#E53B52',
  'dark-orange': '#F96237',
  'light-orange': '#FA9A27',
  'dark-brown': '#ECC42C',
  'light-green': '#A5CF3F',
  'dark-green': '#66D273',
  'light-teal': '#42C4AB',
  'dark-teal': '#32A9E8',
  'light-blue': '#4886DD',
  'dark-purple': '#7B6FED',
  'light-purple': '#A962E0',
  'light-pink': '#E163E1',
  'dark-pink': '#E84F9C',
  'light-red': '#FA91AE',
  'light-warm-gray': '#8DA3A6',
}

export const PROJECT_COLOR_OPTIONS = [
  { value: 'dark-red', label: 'Red' },
  { value: 'dark-orange', label: 'Dark orange' },
  { value: 'light-orange', label: 'Light orange' },
  { value: 'dark-brown', label: 'Brown' },
  { value: 'light-green', label: 'Light green' },
  { value: 'dark-green', label: 'Dark green' },
  { value: 'light-teal', label: 'Light team' },
  { value: 'dark-teal', label: 'Dark team' },
  { value: 'light-blue', label: 'Blue' },
  { value: 'dark-purple', label: 'Dark purple' },
  { value: 'light-purple', label: 'Light purple' },
  { value: 'light-pink', label: 'Light pink' },
  { value: 'dark-pink', label: 'Dark pink' },
  { value: 'light-red', label: 'Light red' },
  { value: 'light-warm-gray', label: 'Gray' },
]

export const PROJECT_COLOR_OPTIONS_MAP = {
  'dark-red': 'Red',
  'dark-orange': 'Dark orange',
  'light-orange': 'Light orange',
  'dark-brown': 'Brown',
  'light-green': 'Light green',
  'dark-green': 'Dark green',
  'light-teal': 'Light team',
  'dark-teal': 'Dark team',
  'light-blue': 'Blue',
  'dark-purple': 'Dark purple',
  'light-purple': 'Light purple',
  'light-pink': 'Light pink',
  'dark-pink': 'Dark pink',
  'light-red': 'Light red',
  'light-warm-gray': 'Gray',
}
