import React, { useContext, useRef } from 'react'
import { useReducer, createContext } from 'react'

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_STORIES':
      return {
        ...state,
        stories: {
          ...state.stories,
          ...action.stories.reduce(
            (acc, story) => ({
              ...acc,
              [story.id]: story,
            }),
            {}
          ),
        },
      }

    case 'ADD_STORY':
      return {
        ...state,
        stories: {
          ...state.stories,
          [action.story.id]: action.story,
        },
      }

    case 'UPDATE_STORY':
      return {
        ...state,
        stories: {
          ...state.stories,
          [action.story.id]: action.story,
        },
      }

    case 'DELETE_STORY':
      return {
        ...state,
        stories: {
          ...state.stories,
          [action.story.id]: undefined,
        },
      }

    case 'SET_PROJECTS':
      return {
        ...state,
        projects: {
          ...state.projects,
          ...action.projects.reduce(
            (acc, project) => ({
              ...acc,
              [project.id]: project,
            }),
            {}
          ),
        },
      }

    case 'ADD_PROJECT':
      return {
        ...state,
        projects: {
          ...state.projects,
          [action.project.id]: action.project,
        },
      }

    case 'UPDATE_PROJECT':
      return {
        ...state,
        projects: {
          ...state.projects,
          [action.project.id]: action.project,
        },
      }

    case 'DELETE_PROJECT':
      return {
        ...state,
        projects: {
          ...state.projects,
          [action.project.id]: undefined,
        },
      }

    case 'SET_ERROR':
      return {
        ...state,
        error: action.error,
      }

    case 'SET_RECORDS': {
      return {
        ...state,
        [action.recordType]: {
          ...state[action.recordType],
          ...action.records.reduce(
            (acc, record) => ({
              ...acc,
              [record.id]: record,
            }),
            {}
          ),
        },
      }
    }

    case 'UPDATE_RECORD': {
      return {
        ...state,
        [action.recordType]: {
          ...state[action.recordType],
          [action.record.id]: {
            ...(state[action.recordType] || {})[action.record.id],
            ...action.record,
          },
        },
      }
    }

    case 'DELETE_RECORD': {
      return {
        ...state,
        [action.recordType]: {
          ...state[action.recordType],
          [action.record.id]: undefined,
        },
      }
    }

    default:
      return state
  }
}

const initialState = {
  stories: {},
  projects: {},
  error: null,
}

const Context = createContext()
export const useStore = () => useContext(Context)

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const setStories = (stories) => dispatch({ type: 'SET_STORIES', stories })
  const addStory = (story) => dispatch({ type: 'ADD_STORY', story })
  const updateStory = (story) => dispatch({ type: 'UPDATE_STORY', story })
  const deleteStory = (story) => dispatch({ type: 'DELETE_STORY', story })

  const setProjects = (projects) => dispatch({ type: 'SET_PROJECTS', projects })
  const addProject = (project) => dispatch({ type: 'ADD_PROJECT', project })
  const updateProject = (project) =>
    dispatch({ type: 'UPDATE_PROJECT', project })
  const deleteProject = (project) =>
    dispatch({ type: 'DELETE_PROJECT', project })

  const setRecords = (recordType, records) =>
    dispatch({ type: 'SET_RECORDS', recordType, records })
  const updateRecord = (recordType, record) =>
    dispatch({ type: 'UPDATE_RECORD', recordType, record })
  const deleteRecord = (recordType, record) =>
    dispatch({ type: 'DELETE_RECORD', recordType, record })
  const getRecord = (recordType, recordId) => {
    const records = state[recordType] || {}
    return records[recordId]
  }
  const getRecords = (recordType) => state[recordType] || {}

  const setError = (story) => dispatch({ type: 'SET_ERROR', story })

  return (
    <Context.Provider
      value={{
        state,
        store: state,
        setStories,
        updateStory,
        deleteStory,
        addStory,

        setProjects: useRef(setProjects).current,
        addProject,
        updateProject,
        deleteProject,

        setRecords,
        getRecord,
        getRecords,
        updateRecord,
        deleteRecord,

        setError,
      }}
    >
      {children}
    </Context.Provider>
  )
}
