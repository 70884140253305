import React from 'react'
import { useRouteMatch, NavLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import AvatarsStack from 'components/avatars-stack/avatars-stack'

export default function ProjectDetailsHeader({ project }) {
  const match = useRouteMatch()

  return (
    <div className="flex justify-between items-center">
      <div className="flex w-full overflow-y-scroll scrolling-touch">
        <NavLink
          to={`${match.url}/stories`}
          className="block pb-1 uppercase text-sm text-silver font-medium mr-6"
          activeClassName="border-b-2 border-primary"
        >
          <FormattedMessage
            id="projects.details.stories.title"
            defaultMessage="Stories"
          />
        </NavLink>

        <NavLink
          to={`${match.url}/calendar`}
          className="block pb-1 uppercase text-sm text-silver font-medium mr-6"
          activeClassName="border-b-2 border-primary"
        >
          <FormattedMessage
            id="projects.details.calendar.title"
            defaultMessage="Calendar"
          />
        </NavLink>

        {project.permissions.owns && (
          <>
            <NavLink
              to={`${match.url}/members`}
              className="block pb-1 uppercase text-sm text-silver font-medium mr-6"
              activeClassName="border-b-2 border-primary"
            >
              <FormattedMessage
                id="projects.details.members.title"
                defaultMessage="Members"
              />
            </NavLink>

            <NavLink
              to={`${match.url}/settings`}
              className="block pb-1 uppercase text-sm text-silver font-medium"
              activeClassName="border-b-2 border-primary"
            >
              <FormattedMessage
                id="projects.details.settings.title"
                defaultMessage="Settings"
              />
            </NavLink>
          </>
        )}
      </div>

      {project.accounts.length > 0 && (
        <>
          <div className="hidden md:block">
            <AvatarsStack
              avatars={[
                {
                  id: project.account.id,
                  name: [
                    project.account.first_name,
                    project.account.last_name,
                  ].join(' '),
                  pictureUrl: project.account.picture_url,
                },
                ...project.accounts.map((account) => ({
                  id: account.id,
                  name: [account.first_name, account.last_name].join(' '),
                  pictureUrl: account.picture_url,
                })),
              ]}
              className="h-10"
              avatarClassName="w-10 h-10 border-2 border-white mx-1"
              maxCount={7}
              tooltipId={`project_accounts-${project.id}`}
              stacked={false}
            />
          </div>
          <div className="md:hidden">
            <AvatarsStack
              avatars={[
                {
                  id: project.account.id,
                  name: [
                    project.account.first_name,
                    project.account.last_name,
                  ].join(' '),
                  pictureUrl: project.account.picture_url,
                },
                ...project.accounts.map((account) => ({
                  id: account.id,
                  name: [account.first_name, account.last_name].join(' '),
                  pictureUrl: account.picture_url,
                })),
              ]}
              className="h-10"
              avatarClassName="w-10 h-10 border-2 border-white mx-1"
              maxCount={2}
              tooltipId={`project_accounts-${project.id}`}
            />
          </div>
        </>
      )}
    </div>
  )
}
