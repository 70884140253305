import React from 'react'
import { FormContext } from 'react-hook-form'
import StorySummary from 'domains/stories/summary'
import { FormattedMessage } from 'react-intl'
import { useCreateStory } from './hooks'

export default function CreateStoryModal({ onDone }) {
  const { onSubmit, methods, projects } = useCreateStory({ onDone })

  return (
    <FormContext {...methods}>
      <form onSubmit={onSubmit}>
        <div className="mb-2">
          <StorySummary editable projects={projects} />
        </div>

        <div>
          <button
            className={`
              w-full h-12 rounded uppercase font-medium bg-primary
              hover:bg-primary-dark text-white disabled:text-silver
              disabled:bg-background
            `}
          >
            <FormattedMessage
              id="stories.calendar.save"
              defaultMessage="Save"
            />
          </button>
        </div>
      </form>
    </FormContext>
  )
}
