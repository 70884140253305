import { useLocation } from 'react-router-dom'
import qs from 'query-string'

export const useStoriesQueryString = () => {
  const location = useLocation()
  const search = qs.parse(location.search)

  const tags = search.tags
    ? Array.isArray(search.tags)
      ? search.tags
      : [search.tags]
    : undefined

  const includeArchived = Object.keys(search).includes('include_archived')

  const projectIds = search.project_ids
    ? Array.isArray(search.project_ids)
      ? search.project_id
      : [search.project_id]
    : undefined

  const projectId = search.project || undefined

  const status = search.status
    ? Array.isArray(search.status)
      ? search.status
      : [search.status]
    : undefined

  return {
    tags,
    project_ids: projectIds,
    project_id: projectId,
    active: includeArchived ? undefined : null,
    status,
  }
}

export default useStoriesQueryString
