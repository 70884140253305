import React from 'react'
import { Home, FileText, Calendar, Briefcase } from 'react-feather'
import { ReactComponent as Logo } from './logo-white.svg'
import api from 'services/api'
import MenuItem from './Sidebar/MenuItem'
import ProjectItem from './Sidebar/ProjectItem'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import qs from 'query-string'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'

const useSidebar = () => {
  const [user] = useAuthState(getAuth())

  const { data, isLoading } = useQuery(
    ['projects', { active: true, sort: '+name ' }],
    async () => {
      const accessToken = await user.getIdToken()

      return api.fetch(
        qs.stringifyUrl({
          url: '/projects',
          query: { active: null, sort: '+name' },
        }),
        { accessToken }
      )
    },
    {
      enabled: !!user,
    }
  )

  return {
    isLoading,
    projects: data?.projects,
  }
}

export default function Sidebar() {
  const { isLoading, projects } = useSidebar()

  return (
    <div className="xl:block bg-black w-64 border-r min-h-full border-outline text-white">
      <Link className="flex items-center justify-center h-16 block" to="/">
        <Logo className="h-6" />
      </Link>

      <div className="py-6">
        <MenuItem to="/" label="dashboard" icon={Home} exact />
        <MenuItem to="/projects" label="projects" icon={Briefcase} />
        <MenuItem to="/stories" label="stories" icon={FileText} />
        <MenuItem to="/calendar" label="calendar" icon={Calendar} />
      </div>

      {!isLoading && (
        <div className="hidden md:block mt-6">
          <div className="px-4 mb-4 text-gray">
            <span style={{ marginLeft: 2 }}>
              <FormattedMessage id="sidebar.projects" />
            </span>
          </div>

          {projects.map((project) => (
            <ProjectItem
              id={project.id}
              name={project.name}
              color={project.color}
              key={project.id}
            />
          ))}
        </div>
      )}
    </div>
  )
}
