import React from 'react'
import Spinner from 'components/spinner/spinner'
import { ReactComponent as Logo } from './logo.svg'

export default function LoadingView() {
  return (
    <div className="h-screen bg-lightBackground flex flex-col justify-center min-h-screen items-center relative">
      <Spinner className="text-silver" />
      <Logo className="w-32 absolute bottom-0 mb-16" />
    </div>
  )
}
