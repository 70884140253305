import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation,
} from 'react-router-dom'
import { IntlProvider } from 'react-intl'

import MainLayout from './layouts/main'
import DefaultLayout from './layouts/default'

import Stories from './pages/stories'
import StoriesCalendar from './pages/stories/calendar'
import CreateStory from './pages/stories/create'
import LoggedOut from './pages/loggedOut'
import DashboardPage from './pages/dashboard'
import CreateProjectPage from './pages/projects/create'
import ProjectsListPage from './pages/projects'
import ProjectDetailsPage from './pages/projects/details'
import StoryPreviewPage from 'pages/stories/preview'
import StoryContentPage from 'pages/stories/content'

import { StoreProvider } from './services/store'
import { HeadProvider } from 'hooks/useHead'
import Head from 'components/head'
import ActivitiesPage from 'pages/activities'
import StoryView from 'views/stories/details'
import { useQuery } from 'react-query'
import LoadingView from 'views/loading/loading'
import api from 'services/api'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'
import LoginPage from 'pages/login'

const useDashboard = () => {
  const [user, loading] = useAuthState(getAuth())

  const { isLoading: accountIsLoading } = useQuery(
    'account',
    async () => {
      const accessToken = await user.getIdToken()

      return api.fetch('/account', { accessToken }).catch(() =>
        api.fetch('/account', {
          method: 'POST',
          accessToken,
        })
      )
    },
    {
      enabled: !!user,
    }
  )

  return {
    isAuthenticated: !!user,
    loading: loading || accountIsLoading,
    accountIsLoading,
  }
}

const Dashboard = () => {
  const { loading, isAuthenticated } = useDashboard()
  const history = useHistory()
  const location = useLocation()

  if (loading) {
    return <LoadingView />
  }

  if (!isAuthenticated) {
    history.push('/login', {
      next: location,
    })

    return <LoadingView />
  }

  return (
    <MainLayout>
      <Switch>
        <Route path="/" exact>
          <DashboardPage />
        </Route>

        <Route path="/stories/create">
          <CreateStory />
        </Route>

        <Route path="/stories/:storyId" exact>
          <StoryView />
        </Route>

        <Route path="/stories" exact>
          <Stories />
        </Route>

        <Route path="/calendar">
          <StoriesCalendar />
        </Route>

        <Route path="/projects/create">
          <CreateProjectPage />
        </Route>

        <Route path="/projects/:projectId">
          <ProjectDetailsPage />
        </Route>

        <Route path="/projects">
          <ProjectsListPage />
        </Route>

        <Route path="/activities">
          <ActivitiesPage />
        </Route>
      </Switch>
    </MainLayout>
  )
}

const App = () => {
  return (
    <HeadProvider>
      <StoreProvider>
        <Head />

        <Switch>
          <Route path="/loggedOut">
            <DefaultLayout>
              <LoggedOut />
            </DefaultLayout>
          </Route>

          <Route path="/stories/:storyId/preview">
            <StoryPreviewPage />
          </Route>

          <Route path="/stories/:storyId/content">
            <StoryContentPage />
          </Route>

          <Route path="/login">
            <DefaultLayout>
              <LoginPage />
            </DefaultLayout>
          </Route>

          <Route path="/">
            <Dashboard />
          </Route>
        </Switch>
      </StoreProvider>
    </HeadProvider>
  )
}

export default function AppRouter() {
  return (
    <IntlProvider locale="fr">
      <Router>
        <App />
      </Router>
    </IntlProvider>
  )
}
