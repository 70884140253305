import React from 'react'
import qs from 'query-string'
import { useStoryContent } from './hooks'
import LoadingView from 'views/loading/loading'

export default function Content() {
  const { isLoading, content } = useStoryContent()

  if (isLoading) {
    return <LoadingView />
  }

  if (content) {
    const contentLink = qs.stringifyUrl({
      url: content.link,
      query: {
        embedded: 'true',
      },
    })

    window.location.replace(contentLink)
  }

  return <LoadingView />
}
