import path from 'path'
import URL from 'url'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'

const API_URL = process.env.REACT_APP_API_URL

class ApiError extends Error {
  constructor(message, { response, errorCode, statusCode, details }) {
    super(message)

    this.name = 'ApiError'
    this.response = response
    this.errorCode = errorCode
    this.statusCode = statusCode
    this.details = details
  }
}

const route = (apiPath) => URL.resolve(API_URL, path.join('v1', apiPath))

const parseResponse = async (response) => {
  if (!response.ok) {
    const json = await response.json()
    const error = new ApiError(json.error.message, {
      response: json,
      errorCode: json.error.code,
      statusCode: response.status,
      details: json.error.details,
    })

    throw error
  }

  if (response.status === 204) {
    return Promise.resolve(null)
  }

  return response.json()
}

const ffetch = async (apiPath, options = {}) => {
  options.headers = {
    ...options.headers,
    'content-type': 'application/json',
  }

  if (options.accessToken) {
    options.headers.Authorization = `Bearer ${options.accessToken}`
  }

  const url = route(apiPath)
  const response = await fetch(url, options)
  const responseData = await parseResponse(response)

  return responseData
}

export const useApi = (opts = {}) => {
  const [user] = useAuthState(getAuth())

  const call = async (apiPath, options = {}) => {
    try {
      options.headers = {
        ...options.headers,
        'content-type': 'application/json',
      }

      if (!opts.guest) {
        const accessToken = await user.getIdToken()

        options.headers = {
          ...options.headers,
          authorization: ['Bearer', accessToken].join(' '),
        }
      }

      return ffetch(apiPath, options)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  return {
    fetch: call,
    route,
  }
}

export default {
  fetch: ffetch,
}
