import React from 'react'
import Dropdown from 'components/dropdown/dropdown'
import { LogOut, Menu } from 'react-feather'
import { useHead } from 'hooks/useHead'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth, signOut } from '@firebase/auth'
import { useHistory } from 'react-router'

const UserMenu = ({ user }) => {
  const history = useHistory()

  const handleLogoutClick = async () => {
    const auth = getAuth()
    await signOut(auth)
    history.push('/loggedOut')
  }

  const Trigger = (
    <button className="cursor-pointer">
      <img
        src={user.photoURL}
        className="rounded-full w-10 h-10"
        alt={user.displayName}
      />
    </button>
  )

  return (
    <Dropdown className="relative" trigger={Trigger}>
      <div className="shadow-md right-0 rounded w-64 z-10 absolute">
        <div className="bg-lightBackground p-4 flex items-center ">
          <div className="w-10 h-10 mr-3">
            <img
              src={user.photoURL}
              className="rounded-full max-w-full"
              alt={user.displayName}
            />
          </div>

          <div className="text-sm">
            <div className="font-medium">{user.displayName}</div>
            <div className="text-gray">{user.email}</div>
          </div>
        </div>

        <button
          className="bg-white p-4 flex items-center w-full"
          onClick={handleLogoutClick}
        >
          <LogOut width={18} className="mr-1" />
          <div className="uppercase font-medium text-sm">Log out</div>
        </button>
      </div>
    </Dropdown>
  )
}

export default function Header({ onDockClick }) {
  const [user, loading] = useAuthState(getAuth())
  const { head } = useHead()

  return (
    <div
      className={`bg-white h-16 border-b border-outline flex justify-between
      items-center px-3 lg:px-8 relative`}
    >
      <div className="flex items-center">
        <button
          onClick={onDockClick}
          className="text-silver outline-none focus:outline-none"
        >
          <Menu />
        </button>

        <div className="ml-6 text-xl hidden lg:block">{head.title}</div>
      </div>

      <div className="text-xl lg:hidden px-3 truncate flex-grow w-2/3 text-center">
        {head.title}
      </div>

      {!loading && !!user ? <UserMenu user={user} /> : <span>&nbsp;</span>}
    </div>
  )
}
