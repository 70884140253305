import React from 'react'
import { X } from 'react-feather'

export const renderTag = (props) => {
  let {
    tag,
    key,
    disabled,
    onRemove,
    getTagDisplayValue,
    classNameRemove,
    ...other
  } = props

  const handleRemove = (e) => {
    e.preventDefault()
    onRemove(key)
  }

  return (
    <span
      key={key}
      {...other}
      className="py-2 px-6 inline-flex bg-background text-silver rounded items-center mr-4 last:mr-0"
    >
      {getTagDisplayValue(tag)}

      {!disabled && (
        <button className="ml-3" onClick={handleRemove} tabIndex="-1">
          <X size={16} />
        </button>
      )}
    </span>
  )
}
