import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { withIntl } from 'services/intl'
import cx from 'classnames'
import AvatarsStack from 'components/avatars-stack/avatars-stack'

const Project = ({ id, name, color, storiesCount, accounts, owner }) => {
  return (
    <Link
      to={`/projects/${id}`}
      className={cx(
        `
          py-4 justify-between block bg-white p-4 rounded flex
          items-end justify-center shadow-md
        `,
        {
          [`text-p-${color}`]: !!color,
          'text-dark': !color,
        }
      )}
      style={{ height: 250 }}
    >
      <div className="w-full h-40 justify-between">
        <div className="text-lg text-center mb-2">{name}</div>
        <div className="text-gray text-sm text-center">
          <FormattedMessage
            id="projects.list.stories_count"
            values={{
              storiesCount,
            }}
          />
        </div>

        {accounts.length > 0 && (
          <div className="flex justify-center mt-10">
            <AvatarsStack
              avatars={[
                {
                  id: owner.id,
                  name: [owner.first_name, owner.last_name].join(' '),
                  pictureUrl: owner.picture_url,
                },
                ...accounts.map((account) => ({
                  id: account.id,
                  name: [account.first_name, account.last_name].join(' '),
                  pictureUrl: account.picture_url,
                })),
              ]}
              className="h-8"
              avatarClassName="w-8 h-8 border-2 border-white"
              maxCount={6}
              tooltipId={`project_accounts-${id}`}
            />
          </div>
        )}
      </div>
    </Link>
  )
}

export default withIntl(require('./project.translations'))(Project)
