import React from 'react'
import classnames from 'classnames'

const COLORS = {
  default: 'text-silver bg-background',
}

export default function ProjectTag({ name, color }) {
  const colorClassName = color
    ? `text-p-${color} bg-p-${color}-light`
    : COLORS.default

  return (
    <span
      className={classnames(colorClassName, 'py-2 px-5 rounded inline-block')}
    >
      {name}
    </span>
  )
}
