import React from 'react'
import { Calendar } from 'react-feather'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import ProjectTag from './project-tag'

export default function StoryListItem({
  title,
  eventAt,
  projectName,
  projectColor,
  ownerName,
  ownerPicture,
  owned,
  status,
}) {
  const sEventAt = eventAt ? moment(eventAt).format('DD MMM YYYY') : null

  return (
    <div className="py-4 text-dark md:flex justify-between bg-white">
      <div className="flex items-center">{title}</div>

      <div className="md:flex">
        {sEventAt && (
          <div className="text-gray flex text-sm items-center">
            <Calendar width={18} className="mr-1" />
            <span>{sEventAt}</span>
          </div>
        )}

        {projectName ? (
          <div className="md:pl-4 mt-2 md:mt-0">
            <ProjectTag name={projectName} color={projectColor} />
          </div>
        ) : (
          !owned &&
          ownerName && (
            <div className="md:pl-4 mt-2 md:mt-0 text-sm flex items-center">
              <img
                src={ownerPicture}
                className="rounded-full w-8 mr-1"
                alt={ownerName}
                data-tip={ownerName}
              />

              <ReactTooltip type="dark" effect="solid" className="bg-black">
                <span>{ownerName}</span>
              </ReactTooltip>

              <span className="lg:hidden">{ownerName}</span>
            </div>
          )
        )}
      </div>
    </div>
  )
}
