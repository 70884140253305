import React from 'react'
import cx from 'classnames'

export default function Spinner({ className = '' }) {
  return (
    <div className={cx('sk-folding-cube', className)}>
      <div className="sk-cube1 sk-cube"></div>
      <div className="sk-cube2 sk-cube"></div>
      <div className="sk-cube4 sk-cube"></div>
      <div className="sk-cube3 sk-cube"></div>
    </div>
  )
}
