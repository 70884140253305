import React from 'react'
import api from 'services/api'
import { useParams } from 'react-router-dom'
import ProjectDetailsView from 'views/projects/details/details'
import { useHead } from 'hooks/useHead'
import { useQuery } from 'react-query'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'

const useProjectDetailsPage = () => {
  const { projectId } = useParams()
  const { setTitle } = useHead()
  const [user] = useAuthState(getAuth())

  const { data, isLoading } = useQuery(
    ['projects', projectId],
    async () => {
      const accessToken = await user.getIdToken()
      return api.fetch(`/projects/${projectId}`, { accessToken })
    },
    {
      onSuccess: ({ project }) => {
        setTitle(project.name)
      },
    }
  )

  return {
    isLoading,
    project: data?.project,
  }
}

const ProjectDetailsPage = () => {
  const { isLoading, project } = useProjectDetailsPage()

  if (isLoading) {
    return null
  }

  return <ProjectDetailsView fetching={isLoading} project={project} />
}

export default ProjectDetailsPage
