import React from 'react'
import { withIntl } from 'services/intl'
import translations from './details.translations.json'
import StoryActionPanels from 'domains/stories/actions-panel/actions-panel'
import { FormContext } from 'react-hook-form'
import StorySummary from 'domains/stories/summary'
import ShareStory from './share'
import ArchiveStory from './archive'
import { FormattedMessage } from 'react-intl'
import StoryDetailsContent from './content'
import { useStoryDetails } from 'views/stories/details/hooks'

const StoryDetailsView = () => {
  const { methods, onSubmit, story, storyId, projects } = useStoryDetails()

  return (
    <>
      {story.archived_at && (
        <div className="-mt-4 mb-8 text-pink">
          <FormattedMessage
            id="stories.details.archived"
            defaultMessage="This story is archived. Click on unarchive."
          />
        </div>
      )}

      <div className="xl:w-2/3 lg:flex">
        <div className="lg:w-2/3">
          <FormContext {...methods}>
            <form className="bg-white p-6" onSubmit={onSubmit}>
              <StorySummary
                editable={story.permissions.update}
                onBlur={onSubmit}
                projects={projects}
                story={story}
                showProject={!!story.project}
              />
            </form>
          </FormContext>

          <div className="bg-white mt-8 p-6">
            <StoryDetailsContent />
          </div>

          {story.permissions.owns && (
            <>
              <div className="bg-white mt-8 p-6">
                <ShareStory accessToken={story.access_token} />
              </div>

              <div className="bg-white mt-8 p-6">
                <ArchiveStory
                  archivedAt={story.archived_at}
                  storyId={story.id}
                />
              </div>
            </>
          )}
        </div>

        <div className="p-6 hidden lg:block lg:w-1/3">
          <StoryActionPanels storyId={storyId} projectId={story.project?.id} />
        </div>
      </div>
    </>
  )
}

export default withIntl(translations)(StoryDetailsView)
