import React from 'react'
import { FormattedMessage } from 'react-intl'
import MemberForm from 'domains/members/form'
import { FormContext } from 'react-hook-form'
import { useStoryMembers } from './hooks'
import StoryMembersList from './list'

export default function StoryMembers() {
  const {
    methods,
    roles,
    isLoading,
    onShowForm,
    formIsShown,
    onHideForm,
    onSubmit,
  } = useStoryMembers()

  const members = roles
    ? roles
        .filter((v) => v)
        .map((role) => ({
          name: [role.account.first_name, role.account.last_name].join(' '),
          role: role.name,
          pictureUrl: role.account.picture_url,
          email: role.account.email,
          roleId: role.id,
        }))
    : []

  return (
    <div>
      <div className="mb-1 text-lg">
        <FormattedMessage
          id="stories.details.share.access"
          defaultMessage="Access"
        />
      </div>

      <div className="mb-3 text-gray text-sm">
        <FormattedMessage
          id="stories.details.share.access.explanation"
          defaultMessage="Give access to your content to people on Edito. They can either ready, comment or edit your content."
        />
      </div>

      {!isLoading && (
        <div className="mb-6">
          <StoryMembersList members={members} />
        </div>
      )}

      {formIsShown ? (
        <FormContext {...methods}>
          <MemberForm onSubmit={onSubmit} onCancel={onHideForm} />
        </FormContext>
      ) : (
        <button
          className="text-primary uppercase font-medium"
          onClick={onShowForm}
        >
          <FormattedMessage
            id="stories.details.share.access.invite"
            defaultMessage="Invite someone to collaborate"
          />
        </button>
      )}
    </div>
  )
}
