import { useLocation } from 'react-router-dom'
import qs from 'query-string'

export default function usePagination() {
  const location = useLocation()
  const { page } = qs.parse(location.search)

  return {
    page,
  }
}
