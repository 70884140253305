import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useForm } from 'react-hook-form'
import classnames from 'classnames'
import { useIntl } from 'react-intl'
import ErrorMessage from 'components/error-message/error-message'

export default function CreateStoryForm({
  onSubmit,
  error,
  disabled,
  fieldErrors,
}) {
  const { register, handleSubmit, errors, setError } = useForm()
  const intl = useIntl()

  useEffect(() => {
    const errors = fieldErrors.map(({ field, code }) => ({
      name: field,
      message: code,
    }))

    setError(errors)
  }, [fieldErrors, setError])

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="md:w-2/3 lg:w-1/2 md:mx-auto xl:w-2/5"
    >
      <div className="text-xl mb-8 text-center">
        <FormattedMessage
          id="stories.create.form.title"
          defaultMessage="Name your story"
        />
      </div>

      <div className="mb-8">
        <input
          ref={register({ required: 'required' })}
          name="title"
          className={classnames('border w-full rounded font-medium p-4', {
            'border-pink': !!errors.title,
            'border-outline': !errors.title,
          })}
          placeholder={intl.formatMessage({
            id: 'stories.create.form.title.placeholder',
            defaultMessage: "What's your story?",
          })}
        />

        <ErrorMessage errors={errors} name="title" />
      </div>

      {error && <div className="text-pink mb-1">{error}</div>}

      <div className="text-center">
        <button
          className="w-full h-12 rounded uppercase font-medium bg-primary hover:bg-primary-dark text-white disabled:text-silver disabled:bg-background"
          disabled={disabled}
        >
          <FormattedMessage
            id="stories.create.form.submit"
            defaultMessage="Start your story"
          />
        </button>
      </div>
    </form>
  )
}
