import qs from 'query-string'
import { useQuery } from 'react-query'
import api from 'services/api'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'

export const useCalendarPanel = () => {
  const [user] = useAuthState(getAuth())

  const { isLoading, data } = useQuery(
    ['projects', { active: true, sort: '+name' }],
    async () => {
      const accessToken = await user.getIdToken()

      return api.fetch(
        qs.stringifyUrl({
          url: '/projects',
          query: {
            active: null,
            sort: '+name',
          },
        }),
        { accessToken }
      )
    }
  )

  return {
    isLoading,
    projects: data?.projects,
  }
}
