import React, { useState } from 'react'
import ActivitiesView from 'views/activities'

export default function ActivitiesPage() {
  const [data] = useState({
    fetching: true,
    activities: [],
  })

  return (
    <ActivitiesView fetching={data.fetching} activities={data.activities} />
  )
}
