import React from 'react'
import StoryDetailsView from 'views/stories/details/details'
import api from 'services/api'
import { useParams } from 'react-router-dom'
import { useHead } from 'hooks/useHead'
import { useQuery } from 'react-query'
import qs from 'query-string'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'

const useStoryDetails = () => {
  const { storyId } = useParams()
  const { setTitle } = useHead()
  const [user] = useAuthState(getAuth())

  const { data: projectsData } = useQuery(
    ['projects', { active: null, sort: '+name' }],
    async () => {
      const accessToken = await user.getIdToken()

      return api.fetch(
        qs.stringifyUrl({
          url: '/projects',
          query: {
            active: null,
            sort: '+name',
          },
        }),
        { accessToken }
      )
    }
  )

  const {
    isLoading,
    data: storyData,
    error,
  } = useQuery(
    ['stories', storyId],
    async () => {
      const accessToken = await user.getIdToken()
      return api.fetch(`/articles/${storyId}`, { accessToken })
    },
    {
      onSuccess: ({ article }) => {
        setTitle(article.title)
      },
    }
  )

  return {
    isLoading,
    story: storyData?.article,
    error,
    projects: projectsData?.projects,
  }
}

const StoryDetails = () => {
  const { isLoading, story, projects, error } = useStoryDetails()

  if (isLoading) {
    return null
  }

  return <StoryDetailsView story={story} projects={projects} error={error} />
}

export default StoryDetails
