import React from 'react'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { withIntl } from 'services/intl'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'

const getLink = (objectType, objectId) => {
  const LINKS = {
    article: `/stories/${objectId}`,
    project: `/projects/${objectId}`,
  }

  return LINKS[objectType]
}

const Activity = ({
  name,
  createdAt,
  objectType,
  object,
  objectName,
  ownerType,
  owner,
}) => {
  const [user] = useAuthState(getAuth())
  const isSelf = ownerType === 'account' && owner.email === user.email

  return (
    <div className="bg-white py-3 px-5 mb-3">
      <div>
        <FormattedMessage
          id={`activities.list.${name}${isSelf ? '.self' : '.other'}`}
          values={{
            name: [owner.first_name, owner.last_name].join(' '),
            objectName: objectName,
            link: (...chunks) => (
              <Link
                to={getLink(objectType, object.id)}
                className="text-primary"
              >
                {chunks}
              </Link>
            ),
          }}
        />
      </div>

      <div className="text-gray text-sm">{moment(createdAt).calendar()}</div>
    </div>
  )
}

export default withIntl(require('./translations.json'))(Activity)
