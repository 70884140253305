import React from 'react'
import { FormattedMessage } from 'react-intl'
import ProjectsList from './projects-list'
import { Link } from 'react-router-dom'
import api from 'services/api'
import qs from 'query-string'
import { useQuery } from 'react-query'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'

const useProjectDashboard = () => {
  const [user] = useAuthState(getAuth())

  const queryParams = {
    active: null,
    per_page: 2,
    sort: '-articles_count',
  }

  const { data, isLoading } = useQuery(['projects', queryParams], async () => {
    const accessToken = await user.getIdToken()

    return api.fetch(
      qs.stringifyUrl({
        url: '/projects',
        query: queryParams,
      }),
      { accessToken }
    )
  })

  return {
    isLoading,
    projects: data?.projects,
  }
}

export default function ProjectsDashboard() {
  const { isLoading, projects } = useProjectDashboard()

  return (
    <div>
      <div className="text-silver mb-6  flex justify-between items-center">
        <span className="text-xl">
          <FormattedMessage
            id="dashboard.projects.title"
            defaultMessage="Your projects"
          />
        </span>

        <Link to="/projects" className="uppercase text-sm">
          <FormattedMessage
            id="dashboard.projects.all_projects"
            defaultMessage="All projects"
          />
        </Link>
      </div>

      <ProjectsList loading={isLoading} projects={projects} />
    </div>
  )
}
