import React from 'react'
import { withIntl } from 'services/intl'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import ProjectStories from '../stories'
import ProjectDetailsHeader from './header'
import ProjectSettings from 'domains/projects/settings'
import ProjectCalendar from './calendar/index.js'
import { FormattedMessage } from 'react-intl'
import ProjectMembers from './members'

const ProjectDetailsView = ({ project }) => {
  const match = useRouteMatch()

  return (
    <div className="relative">
      {project && project.archived_at && (
        <div className="-mx-3 -mt-8 mb-8 lg:-mx-8 px-8 py-4 text-pink bg-pink-light">
          <FormattedMessage
            id="projects.details.archived"
            defaultMessage="This project is archived. Click on settings to unarchive it."
          />
        </div>
      )}

      <div className="mb-8">
        <ProjectDetailsHeader project={project} />
      </div>

      <Switch>
        <Route path={match.path} exact>
          <Redirect to={`${match.url}/stories`} />
        </Route>

        <Route path={`${match.path}/stories`}>
          <ProjectStories />
        </Route>

        {project.permissions.owns && (
          <Route path={`${match.path}/settings`}>
            <ProjectSettings />
          </Route>
        )}

        <Route path={`${match.path}/calendar`}>
          <ProjectCalendar />
        </Route>

        {project.permissions.owns && (
          <Route path={`${match.path}/members`}>
            <ProjectMembers />
          </Route>
        )}
      </Switch>
    </div>
  )
}

export default withIntl(require('./translations.json'))(ProjectDetailsView)
