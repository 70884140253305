import React from 'react'
import { useQuery } from 'react-query'
import api from 'services/api'
import { useParams } from 'react-router-dom'
import StoryContent from 'domains/stories/content'
import { useHead } from 'hooks/useHead'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'

const useStoryContentPage = () => {
  const { storyId } = useParams()
  const { setTitle } = useHead()
  const [user] = useAuthState(getAuth())

  const { isLoading } = useQuery(
    ['stories', storyId],
    async () => {
      const accessToken = await user.getIdToken()
      return api.fetch(`/articles/${storyId}`, { accessToken })
    },
    {
      onSuccess: ({ article }) => setTitle(article.title),
    }
  )

  return {
    isLoading,
  }
}

const StoryContentPage = () => {
  const { isLoading } = useStoryContentPage()

  if (isLoading) {
    return null
  }

  return <StoryContent />
}

export default StoryContentPage
