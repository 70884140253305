import { useQuery } from 'react-query'
import api from 'services/api'
import qs from 'query-string'
import { useLocation } from 'react-router-dom'
import useCalendar from 'hooks/calendar/use-calendar'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'

export const useStoriesCalendar = () => {
  const location = useLocation()
  const {
    selected,
    isModalOpen,
    closeModal,
    handleEventDrop,
    handleEventClick,
    handleNewEventClick,
    handleProjectClick,
    handleDisplayOptionClick,

    filter,
    settingsModal,
  } = useCalendar()

  const [user] = useAuthState(getAuth())

  const { project_ids: projectIds } = qs.parse(location.search)

  const { isLoading, data } = useQuery(
    ['stories', { active: true, projectIds }],
    async () => {
      const accessToken = await user.getIdToken()
      const url = qs.stringifyUrl(
        {
          url: '/articles',
          query: {
            active: null,
            project_ids: Array.isArray(projectIds) ? projectIds : [projectIds],
          },
        },
        {
          arrayFormat: 'bracket',
        }
      )

      return api.fetch(url, { accessToken })
    }
  )

  return {
    isLoading,
    stories: data?.articles,

    selected,
    isModalOpen,
    closeModal,
    handleEventDrop,
    handleEventClick,
    handleNewEventClick,
    handleProjectClick,
    handleDisplayOptionClick,

    filter,
    settingsModal,
  }
}
