import { useReducer } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'query-string'
import api from 'services/api'
import { useQuery } from 'react-query'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'

const reducer = (state, { type, ...action }) => {
  switch (type) {
    case 'SET_FILTER':
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.filter,
        },
      }
    default:
      return state
  }
}

const getInitialState = (location) => {
  const search = qs.parse(location.search)

  const tags = search.tags
    ? Array.isArray(search.tags)
      ? search.tags
      : [search.tags]
    : []

  const status = search.status
    ? Array.isArray(search.status)
      ? search.status
      : [search.status]
    : []

  const includeArchived = Object.keys(search).includes('include_archived')

  return {
    isVisible: false,

    filter: {
      tags,
      includeArchived,
      status,
    },
  }
}

const useStoryFilters = () => {
  const location = useLocation()
  const [uiState, updateUIState] = useReducer(
    reducer,
    getInitialState(location)
  )
  const history = useHistory()
  const [user] = useAuthState(getAuth())

  const { data, isLoading } = useQuery('tags', async () => {
    const accessToken = await user.getIdToken()
    return api.fetch('/tags', { accessToken })
  })

  const handleTagClick = (e) => {
    let tags = uiState.filter.tags

    if (e.target.checked) {
      tags.push(e.target.value)
    } else {
      const tagIndex = tags.findIndex((tag) => tag === e.target.value)

      if (tagIndex < 0) {
        return
      }

      tags = [...tags.slice(0, tagIndex), ...tags.slice(tagIndex + 1)]
    }

    const search = qs.stringify({
      ...qs.parse(location.search),
      tags,
    })

    history.push({
      search,
    })

    updateUIState({ type: 'SET_FILTER', filter: { tags } })
  }

  const handleArchivedChange = (e) => {
    if (e.target.checked) {
      updateUIState({ type: 'SET_FILTER', filter: { includeArchived: true } })

      const search = qs.stringify({
        ...qs.parse(location.search),
        include_archived: null,
      })

      history.push({
        search,
      })

      return
    }

    updateUIState({ type: 'SET_FILTER', filter: { includeArchived: false } })

    const search = qs.stringify({
      ...qs.parse(location.search),
      include_archived: undefined,
    })

    history.push({
      search,
    })
  }

  const handleStatusClick = (e) => {
    let status = uiState.filter.status

    if (e.target.checked) {
      status.push(e.target.value)
    } else {
      const tagIndex = status.findIndex((tag) => tag === e.target.value)

      if (tagIndex < 0) {
        return
      }

      status = [...status.slice(0, tagIndex), ...status.slice(tagIndex + 1)]
    }

    const search = qs.stringify({
      ...qs.parse(location.search),
      status,
    })

    history.push({
      search,
    })

    updateUIState({ type: 'SET_FILTER', filter: { status } })
  }

  const isStatusIncluded = (status) => uiState.filter.status.includes(status)

  return {
    isLoading,
    tags: data?.tags,
    onTagClick: handleTagClick,
    onArchiveChange: handleArchivedChange,
    uiState,
    onStatusClick: handleStatusClick,
    isStatusIncluded,
  }
}

export default useStoryFilters
