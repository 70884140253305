import React from 'react'
import moment from 'moment'
import { Calendar } from 'react-feather'
import EmptyStories from './empty-stories'
import { Link, useRouteMatch } from 'react-router-dom'

const Story = ({ id, title, eventAt }) => {
  const sEventAt = eventAt ? moment(eventAt).format('DD MMM YYYY') : null

  return (
    <div to={`/stories/${id}`} className="py-4 text-dark flex justify-between">
      <div className="w-2/3">{title}</div>

      {sEventAt && (
        <div className="text-gray flex text-sm items-center justify-end">
          <Calendar width={18} className="mr-1" />
          <span>{sEventAt}</span>
        </div>
      )}
    </div>
  )
}

export default function StoriesList({ fetching, stories }) {
  const match = useRouteMatch()
  const newStoryMatch = useRouteMatch('/projects/:projectId/stories/create')

  if (fetching) {
    return null
  }

  if (!stories.length && newStoryMatch) {
    return null
  }

  if (!stories.length) {
    return (
      <div className="mt-20">
        <EmptyStories />
      </div>
    )
  }

  return (
    <div className="md:bg-white md:px-6 py-2 -mx-3 md:mx-0">
      {stories.map((story) => (
        <div
          className={`
            mb-1 md:mb-0 px-3 md:px-0 bg-white md:border-t
            md:border-outline first:border-t-0
          `}
          key={story.id}
        >
          <Link className="block" to={`${match.url}/${story.id}`}>
            <Story title={story.title} eventAt={story.event_at} />
          </Link>
        </div>
      ))}
    </div>
  )
}
