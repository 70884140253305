import React from 'react'
import { Link } from 'react-router-dom'
import StoryListItem from 'domains/stories/list/item'

export default function StoriesList({ stories }) {
  return (
    <>
      <div className="md:bg-white md:px-6 py-2 -mx-3 md:mx-0">
        {stories.map((story) => (
          <div
            className="mb-1 md:mb-0 px-3 md:px-0 bg-white md:border-t md:border-outline first:border-t-0"
            key={story.id}
          >
            <>
              <Link
                to={`/stories/${story.id}`}
                className="text-left lg:block w-full outline-none focus:outline-none"
              >
                <StoryListItem
                  title={story.title}
                  eventAt={story.event_at}
                  projectName={story.project && story.project.name}
                  projectColor={story.project && story.project.color}
                  ownerName={[
                    story.account.first_name,
                    story.account.last_name,
                  ].join(' ')}
                  owned={story.permissions.owns}
                  ownerPicture={story.account.picture_url}
                  status={story.status}
                />
              </Link>
            </>
          </div>
        ))}
      </div>
    </>
  )
}
