import React from 'react'
import CreateProjectView from 'views/projects/create/create'
import { useApi } from 'services/api'
import { useReducer } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

const reducer = (state, { type, story, error }) => {
  switch (type) {
    case 'SUBMIT':
      return {
        ...state,
        submitting: true,
        error: null,
      }

    case 'SUBMIT_SUCCESS':
      return {
        ...state,
        submitting: false,
        story,
        error: null,
      }

    case 'SUBMIT_ERROR':
      return {
        ...state,
        submitting: false,
        error: error,
      }

    default:
      return state
  }
}

const CreateProject = () => {
  const [state, dispatch] = useReducer(reducer, {})
  const api = useApi()
  const { project, submitting, error } = state
  const location = useLocation()
  const history = useHistory()

  const handleSubmit = ({ name }) => {
    const createProjectAsync = async () => {
      await dispatch({ type: 'SUBMIT' })

      try {
        const { project } = await api.fetch('/projects', {
          method: 'POST',
          body: JSON.stringify({
            project: {
              name,
            },
          }),
        })

        await dispatch({ type: 'SUBMIT_SUCCESS', project })

        const redirectTo =
          (location.state && location.state.redirectTo) ||
          `/projects/${project.id}`
        history.push(redirectTo)
      } catch (error) {
        if (error.name !== 'ApiError') {
          throw error
        }

        await dispatch({
          type: 'SUBMIT_ERROR',
          error,
        })
      }
    }

    createProjectAsync()
  }

  return (
    <CreateProjectView
      onSubmit={handleSubmit}
      submitting={submitting}
      error={error}
      complete={!!project}
      projectId={project && project.id}
    />
  )
}

export default CreateProject
