import { useReducer } from 'react'

export default function useMembersList() {
  const reducer = (state, action) => {
    switch (action.type) {
      case 'SHOW_FORM':
        return {
          ...state,
          formIsShown: true,
        }

      case 'HIDE_FORM':
        return {
          ...state,
          formIsShown: false,
        }

      default:
        return state
    }
  }

  const [uiState, dispatch] = useReducer(reducer, {
    formIsShown: false,
  })

  const showForm = () => dispatch({ type: 'SHOW_FORM' })
  const hideForm = () => dispatch({ type: 'HIDE_FORM' })

  return {
    formIsShown: uiState.formIsShown,
    showForm,
    hideForm,
  }
}
