import React from 'react'
import { useIntl } from 'react-intl'
import translations from './create.translations.json'
import { withIntl } from 'services/intl'
import CreateStoryForm from './form'
import { ReactComponent as Illustration } from './undraw_publish_article_icso.svg'

const CreateStoryView = ({
  onSubmit,
  submitting,
  error,
  complete,
  storyId,
}) => {
  const intl = useIntl()
  const errorMessage = error
    ? intl.formatMessage({
        id: `stories.errors.${error.errorCode}`,
      })
    : null
  const fieldErrors = error ? error.details.field_errors : []

  return (
    <div className="py-20">
      <Illustration
        className="w-full md:w-1/2 xl:w-1/4 mb-10 mx-auto"
        style={{ height: 250 }}
      />

      <CreateStoryForm
        disabled={submitting}
        error={errorMessage}
        fieldErrors={fieldErrors}
        onSubmit={onSubmit}
      />
    </div>
  )
}

export default withIntl(translations)(CreateStoryView)
