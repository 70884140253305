import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { Calendar, Briefcase } from 'react-feather'
import { withIntl } from 'services/intl'

const StoryActionPanels = ({ projectId }) => {
  return (
    <div>
      <div className="text-gray mb-4 hidden lg:block">
        <FormattedMessage id="stories.panel.actions" defaultMessage="Actions" />
      </div>

      <div>
        {projectId && (
          <Link
            to={`/projects/${projectId}`}
            className="flex uppercase items-center text-sm font-medium mt-4 first:mt-0"
          >
            <Briefcase size={16} className="mr-2" />{' '}
            <FormattedMessage
              id="stories.panel.project"
              defaultMessage="Go to project"
            />
          </Link>
        )}

        <Link
          to={`/calendar`}
          className="flex uppercase items-center text-sm font-medium mt-4 first:mt-0"
        >
          <Calendar size={16} className="mr-2" />{' '}
          <FormattedMessage
            id="stories.panel.calendar"
            defaultMessage="Show in calendar"
          />
        </Link>
      </div>
    </div>
  )
}

export default withIntl(require('./translations'))(StoryActionPanels)
