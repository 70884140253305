import { useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryCache } from 'react-query'
import api from 'services/api'
import qs from 'query-string'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'

export default function useStoryModal(storyId) {
  const queryCache = useQueryCache()

  const methods = useForm({
    defaultValues: {
      tags: [],
    },
  })

  const [user] = useAuthState(getAuth())

  const { isLoading, data } = useQuery(
    ['stories', storyId],
    async () => {
      const accessToken = await user.getIdToken()
      return api.fetch(`/articles/${storyId}`, { accessToken })
    },
    {
      onSuccess: ({ article: story }) => {
        methods.reset({
          title: story.title,
          notes: story.notes || '',
          publishedOn: story.published_on && new Date(story.published_on),
          dueOn: story.due_on && new Date(story.due_on),
          project: story.project && {
            value: story.project.id,
            label: story.project.name,
            color: story.project.color,
          },
          tags: story.tags,
          status: {
            value: story.status,
          },
        })
      },
    }
  )

  const [updateStory] = useMutation(
    async ({
      title,
      notes,
      metaDescription,
      attachments,
      metaTitle,
      dueOn,
      publishedOn,
      project,
      tags,
      status,
    }) => {
      const data = {
        article: {
          title,
          notes,
          due_on: dueOn?.toISOString(),
          published_on: publishedOn?.toISOString(),
          meta_title: metaTitle,
          meta_description: metaDescription,
          enclosed_files: attachments ? attachments.map((a) => a.value) : [],
          project_id: project?.id,
          tags,
          status: status?.value,
        },
      }

      const accessToken = await user.getIdToken()

      return api.fetch(`/articles/${storyId}`, {
        method: 'PUT',
        body: JSON.stringify(data),
        accessToken,
      })
    },
    {
      onSuccess: (data) => queryCache.setQueryData(['stories', storyId], data),
      onError: (error) => {
        if (error.errorCode !== 'resource_invalid') {
          throw error
        }

        const fieldErrors = error?.details?.field_errors
        fieldErrors.forEach(({ field, code }) => methods.setError(field, code))
      },
    }
  )

  const projectsQuery = useQuery(
    ['projects', { active: true, sort: '+name', account: true }],
    async () => {
      const accessToken = await user.getIdToken()
      const { account } = await api.fetch('/account', { accessToken })

      return api.fetch(
        qs.stringifyUrl({
          url: '/projects',
          query: {
            active: null,
            sort: '+name',
            account_id: account.id,
          },
        }),
        { accessToken }
      )
    }
  )

  return {
    isLoading: isLoading,
    story: data?.article,
    methods,
    onSubmit: methods.handleSubmit(updateStory),
    projects: projectsQuery.data?.projects,
  }
}
