import { useLocation } from 'react-router-dom'
import { useHead } from 'hooks/useHead'
import { withIntl } from 'services/intl'
import { useIntl } from 'react-intl'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { matchPath } from 'react-router'

const MATCH_PATHS = {
  '/': 'dashboard',
  '/projects': 'projects',
  '/stories': 'stories',
  '/calendar': 'calendar',
  '/stories/create': 'create_story',
  '/activities': 'activities',
}

const Head = () => {
  const location = useLocation()
  const { head, setTitle } = useHead()
  const intl = useIntl()

  const matchedPath = Object.keys(MATCH_PATHS).find((path) =>
    matchPath(location.pathname, {
      path,
      exact: true,
    })
  )

  useEffect(() => {
    if (!matchedPath) {
      return
    }

    if (!MATCH_PATHS[matchedPath]) {
      return
    }

    const title = intl.formatMessage({
      id: MATCH_PATHS[matchedPath],
      defaultMessage: MATCH_PATHS[matchedPath],
    })

    setTitle(title)
  }, [head.title, intl, matchedPath, setTitle])

  return (
    <Helmet>
      <title>
        {head.title ? [head.title, 'Edito'].join(' – ') : 'Edito'} – the content
        studio
      </title>
    </Helmet>
  )
}

export default withIntl(require('./translations.json'))(Head)
