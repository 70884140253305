import React from 'react'
import { Briefcase } from 'react-feather'
import { FormattedMessage } from 'react-intl'
import Checkbox from 'components/checkbox/checkbox'

export default function ProjectCalendarPanel({ filter, onDisplayOptionClick }) {
  return (
    <div>
      <div className="text-silver text-sm font-medium mr-4 uppercase flex items-center mb-3">
        <Briefcase className="mr-2 inline" size={18} />

        <FormattedMessage
          id="stories.calendar.displayOptions"
          defaultMessage="Display options"
        />
      </div>

      <div>
        <Checkbox
          value="show_due_date"
          name="showDueDate"
          checked={filter.showDueDate}
          onChange={onDisplayOptionClick}
        >
          <FormattedMessage
            id="stories.calendar.options.dueDates"
            defaultMessage="Due date"
          />
        </Checkbox>
      </div>
    </div>
  )
}
