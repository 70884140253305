import React, { useReducer, useRef, useEffect, useCallback } from 'react'
import cx from 'classnames'

const reducer = (state, action) => {
  switch (action.type) {
    case 'FOCUS':
      return {
        ...state,
        focus: true,
      }

    case 'BLUR':
      return {
        ...state,
        focus: false,
      }

    default:
      return state
  }
}

const noop = () => {}

export default function InlineEdit({
  wrapper: Wrapper = 'div',
  wrapperClassName = null,
  wrapperActiveClassName = null,
  onFocus = noop,
  onBlur = noop,
  overrideOnFocus,
  children,
}) {
  const wrapperRef = useRef(null)
  const inputRef = useRef()

  const [state, dispatch] = useReducer(reducer, {
    focus: false,
  })

  const handleClick = async () => {
    if (state.focus) {
      return
    }

    await dispatch({ type: 'FOCUS' })
    onFocus()

    if (!overrideOnFocus && inputRef && inputRef.current) {
      inputRef.current.focus()
    }
  }

  const handleWindowClick = useCallback(
    async (event) => {
      if (!wrapperRef.current || wrapperRef.current.contains(event.target)) {
        return
      }

      if (state.focus) {
        await dispatch({ type: 'BLUR' })
        onBlur()
      }
    },
    [onBlur, state.focus]
  )

  useEffect(() => {
    window.addEventListener('click', handleWindowClick)
    window.addEventListener('touchstart', handleWindowClick)

    return () => {
      window.removeEventListener('click', handleWindowClick)
      window.removeEventListener('touchstart', handleWindowClick)
    }
  }, [handleWindowClick])

  const { focus } = state

  return (
    <Wrapper
      className={cx(
        'border hover:border-outline hover:cursor-pointer rounded p-2 -mx-2',
        wrapperClassName,
        {
          'border-transparent': !focus,
          'border-outline': focus,
          [wrapperActiveClassName]: focus,
        }
      )}
      onClick={handleClick}
      ref={wrapperRef}
    >
      <div className={cx('inline', { hidden: focus })}>{children[0]}</div>
      <div className={cx({ hidden: !focus })}>
        <label
          ref={inputRef}
          onBlur={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          {children[1]}
        </label>
      </div>
    </Wrapper>
  )
}
