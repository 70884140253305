import React from 'react'
import StorySummaryForm from '../form/form'
import { FormattedMessage, FormattedDate } from 'react-intl'
import ProjectTag from 'domains/projects/tag'
import TagsList from 'components/tags-list'

const StorySummary = ({ editable, story, showProject, ...props }) => {
  if (editable) {
    return <StorySummaryForm {...props} />
  }

  return (
    <div className="">
      <div className="text-2xl mb-6">{story.title}</div>
      {showProject && (
        <div className="mb-6">
          <div className="mb-3">
            <FormattedMessage
              id="stories.summary.projectId"
              defaultMessage="Project"
            />
          </div>

          <div>
            {story.project && (
              <ProjectTag
                name={story.project.name}
                color={story.project.color}
              />
            )}

            {!story.project && (
              <span className="text-gray">
                <FormattedMessage
                  id="stories.summary.projectId.none"
                  defaultMessage="No project associated"
                />
              </span>
            )}
          </div>
        </div>
      )}

      <div className="flex justify-between items-center mb-6">
        <div className="w-1/2 md:w-1/3">
          <div className="mb-3">
            <FormattedMessage
              id="stories.summary.dueOn"
              defaultMessage="Deadline"
            />
          </div>

          <div>
            {story.due_on && (
              <span>
                <FormattedDate
                  value={new Date(story.due_on)}
                  year="numeric"
                  month="short"
                  day="2-digit"
                />
              </span>
            )}

            {!story.due_on && (
              <span className="text-gray">
                <FormattedMessage
                  id="stories.summary.dueOn.none"
                  defaultMessage="No deadline"
                />
              </span>
            )}
          </div>
        </div>

        <div className="w-1/2 md:w-1/3">
          <div className="mb-3">
            <FormattedMessage
              id="stories.summary.publishedOn"
              defaultMessage="Publication date"
            />
          </div>

          <div>
            {story.published_on && (
              <FormattedDate
                value={new Date(story.published_on)}
                year="numeric"
                month="short"
                day="2-digit"
              />
            )}

            {!story.published_on && (
              <span className="text-gray">
                <FormattedMessage
                  id="stories.summary.publishedOn.none"
                  defaultMessage="No publication date"
                />
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="mb-6">
        <div className="mb-3">
          <FormattedMessage
            id="stories.summary.notes"
            defaultMessage="Description"
          />
        </div>

        <div className="whitespace-pre-line">
          {story.notes || (
            <span className="text-gray">
              <FormattedMessage
                id="stories.summary.notes.none"
                defaultMessage="No notes"
              />
            </span>
          )}
        </div>
      </div>

      <div className="mb-6">
        <div className="mb-3">
          <FormattedMessage id="stories.summary.tags" defaultMessage="Tags" />
        </div>

        <div>
          {story.tags.length ? (
            <TagsList tags={story.tags} />
          ) : (
            <span className="text-gray">
              <FormattedMessage
                id="stories.summary.tags.none"
                defaultMessage="No tags"
              />
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default StorySummary
