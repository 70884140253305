import React from 'react'

const TagsList = ({ tags }) => (
  <div>
    {tags.map((tag) => (
      <span
        key={tag}
        className="py-2 px-6 inline-block bg-background text-silver rounded mr-4 last:mr-0"
      >
        {tag}
      </span>
    ))}
  </div>
)

export default TagsList
