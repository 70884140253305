import React from 'react'
import api from 'services/api'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import LoadingView from 'views/loading/loading'

const useStoryPreview = () => {
  const { storyId } = useParams()

  const { isLoading, isIdle, data } = useQuery(
    ['stories-details-content', storyId],
    async () => {
      return api.fetch(`/articles/${storyId}/preview`)
    }
  )

  return {
    isLoading,
    story: data?.article,
    storyId,
    isIdle,
  }
}
const StoryPreviewPage = () => {
  const { isLoading, story } = useStoryPreview()

  if (isLoading) {
    return <LoadingView />
  }

  if (story && story.content_link) {
    window.location.replace(story.content_link)
  }

  return null
}

export default StoryPreviewPage
