import React from 'react'
import { IntlProvider } from 'react-intl'

export const currentLocale = 'fr'
export const withIntl = (messages) => (Component) => {
  return (props) => {
    return (
      <IntlProvider locale="fr" messages={messages.fr}>
        <Component {...props} />
      </IntlProvider>
    )
  }
}
