import React from 'react'
import CreateStoryView from 'views/stories/create/create'
import { useApi } from 'services/api'
import { useReducer } from 'react'
import { useHistory } from 'react-router-dom'

const reducer = (state, { type, story, error }) => {
  switch (type) {
    case 'SUBMIT':
      return {
        ...state,
        submitting: true,
        error: null,
      }

    case 'SUBMIT_SUCCESS':
      return {
        ...state,
        submitting: false,
        story,
        error: null,
      }

    case 'SUBMIT_ERROR':
      return {
        ...state,
        submitting: false,
        error: error,
      }

    default:
      return state
  }
}

const CreateStory = () => {
  const [state, dispatch] = useReducer(reducer, {})
  const api = useApi()
  const history = useHistory()
  const { story, submitting, error } = state

  const handleSubmit = ({ title }) => {
    const createStoryAsync = async () => {
      await dispatch({ type: 'SUBMIT' })

      try {
        const { article: story } = await api.fetch('/articles', {
          method: 'POST',
          body: JSON.stringify({
            article: {
              title,
            },
          }),
        })

        history.push(`/stories/${story.id}`)
      } catch (error) {
        if (error.name !== 'ApiError') {
          throw error
        }

        await dispatch({
          type: 'SUBMIT_ERROR',
          error,
        })
      }
    }

    createStoryAsync()
  }

  return (
    <CreateStoryView
      onSubmit={handleSubmit}
      submitting={submitting}
      error={error}
      storyId={story && story.id}
    />
  )
}

export default CreateStory
