import React from 'react'
import { FormContext, useForm } from 'react-hook-form'
import api from 'services/api'
import { useHistory, useParams, Link } from 'react-router-dom'
import StorySummaryForm from 'domains/stories/form/form'
import { FormattedMessage } from 'react-intl'
import { ArrowLeftCircle } from 'react-feather'
import { useQueryCache } from 'react-query'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'

const useProjectStoryCreate = () => {
  const { projectId } = useParams()
  const history = useHistory()
  const queryCache = useQueryCache()
  const [user] = useAuthState(getAuth())

  const methods = useForm({
    defaultValues: {
      tags: [],
    },
  })

  const submit = async ({
    title,
    notes,
    metaDescription,
    attachments,
    metaTitle,
    dueOn,
    publishedOn,
  }) => {
    try {
      const accessToken = await user.getIdToken()

      const data = {
        article: {
          title,
          notes,
          due_on: dueOn?.toISOString(),
          published_on: publishedOn?.toISOString(),
          meta_title: metaTitle,
          meta_description: metaDescription,
          enclosed_files: attachments ? attachments.map((a) => a.value) : [],
          project_id: projectId,
        },
      }

      const { article: story } = await api.fetch('/articles', {
        method: 'POST',
        body: JSON.stringify(data),
        accessToken,
      })

      queryCache.invalidateQueries(['stories', { projectId }])
      history.push(`/projects/${projectId}/stories/${story.id}`)
    } catch (error) {
      if (error.errorCode !== 'resource_invalid') {
        throw error
      }

      const fieldErrors = error?.details?.field_errors
      methods.setError(
        fieldErrors.map(({ field, code }) => ({
          name: field,
          message: code,
        }))
      )
    }
  }

  return {
    methods,
    onSubmit: methods.handleSubmit(submit),
    projectId,
  }
}

export default function ProjectStoryCreate() {
  const { methods, onSubmit, projectId } = useProjectStoryCreate()

  return (
    <div>
      <form className="xl:w-2/3 lg:flex" onSubmit={onSubmit}>
        <div className="lg:w-2/3 mb-6">
          <div className="border-b border-outline px-6 bg-white py-4">
            <Link to={`/projects/${projectId}/stories`} className="text-silver">
              <ArrowLeftCircle className="mr-1 inline-block" size={18} />
            </Link>
          </div>

          <div>
            <FormContext {...methods}>
              <div className="bg-white p-6">
                <StorySummaryForm unavailableFields={{ project: true }} />
              </div>
            </FormContext>
          </div>
        </div>

        <div className="lg:w-1/3">
          <div className="text-center">
            <button className="h-12 w-full md:w-64 rounded uppercase font-medium bg-primary hover:bg-primary-dark text-white disabled:text-silver disabled:bg-background">
              <FormattedMessage
                id="projects.details.stories.form.save"
                defaultMessage="Save"
              />
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
