import api from 'services/api'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryCache } from 'react-query'
import useMembersList from 'domains/members/list/use-list'
import { useIntl } from 'react-intl'
import { useMemberForm } from 'domains/members/form/form'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'

export const useStoryMembers = () => {
  const { storyId } = useParams()
  const { showForm, hideForm, formIsShown } = useMembersList()
  const methods = useForm()
  const queryCache = useQueryCache()
  const [user] = useAuthState(getAuth())

  const { data, isLoading } = useQuery(['roles', { storyId }], async () => {
    const accessToken = await user.getIdToken()
    return api.fetch(`/articles/${storyId}/roles`, { accessToken })
  })

  const [addRoleMutation] = useMutation(
    async ({ account, name }) => {
      const accessToken = await user.getIdToken()
      const { accounts } = await api.fetch(
        `/accounts?email=${encodeURIComponent(account.email)}`,
        {
          accessToken,
        }
      )

      if (!accounts.length) {
        const error = new Error('nonexistent')
        error.errorCode = 'resource_invalid'
        error.details = {
          field_errors: [
            {
              field: 'account.email',
              code: 'nonexistent',
            },
          ],
        }

        return Promise.reject(error)
      }

      const accountData = accounts[0]
      const roleData = {
        account_id: accountData.id,
        name: name.value,
      }

      return api.fetch(`/articles/${storyId}/roles`, {
        accessToken,
        method: 'POST',
        body: JSON.stringify({
          role: roleData,
        }),
      })
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries(['roles', { storyId }], {
          refetchInactive: true,
        })
        hideForm()
      },
      onError: (error) => {
        if (error.errorCode !== 'resource_invalid') {
          throw error
        }

        const fieldErrors = error?.details?.field_errors

        methods.setError(
          fieldErrors.map(({ field, code }) => ({
            name: field,
            message: code,
          }))
        )
      },
    }
  )

  const handleSubmit = methods.handleSubmit(addRoleMutation)

  return {
    methods,
    isLoading,
    formIsShown,
    roles: data?.roles,
    onShowForm: showForm,
    onHideForm: hideForm,
    onSubmit: handleSubmit,
  }
}

export const useStoryMember = ({ role, roleId }) => {
  const intl = useIntl()
  const { accessOptions, accessOptionsLabelsDict } = useMemberForm()
  const queryCache = useQueryCache()
  const { storyId } = useParams()
  const [user] = useAuthState(getAuth())

  const methods = useForm({
    defaultValues: {
      name: {
        value: role,
        label: accessOptionsLabelsDict[role],
      },
    },
  })

  const [deleteRoleMutation] = useMutation(
    async () => {
      const accessToken = await user.getIdToken()
      return api.fetch(`/roles/${roleId}`, {
        method: 'DELETE',
        accessToken,
      })
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries(['roles', { storyId }], {
          refetchInactive: true,
        })
      },
    }
  )

  const [updateRoleMutation] = useMutation(
    async ({ name }) => {
      const accessToken = await user.getIdToken()
      const roleData = {
        name,
      }

      return api.fetch(`/roles/${roleId}`, {
        method: 'PUT',
        body: JSON.stringify({
          role: roleData,
        }),
        accessToken,
      })
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries(['roles', { storyId }], {
          refetchInactive: true,
        })
      },
      onError: (error) => {
        if (error.errorCode !== 'resource_invalid') {
          throw error
        }

        const fieldErrors = error?.details?.field_errors

        methods.setError(
          fieldErrors.map(({ field, code }) => ({
            name: field,
            message: code,
          }))
        )
      },
    }
  )

  const handleUpdate = methods.handleSubmit(({ name }) =>
    updateRoleMutation({ name: name.value })
  )
  const handleDelete = (roleId) => deleteRoleMutation({ roleId })

  return {
    accessOptions,
    methods,
    intl,
    onUpdate: handleUpdate,
    onDelete: handleDelete,
  }
}
