import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const Project = ({ id, name, storiesCount }) => {
  return (
    <Link
      to={`/projects/${id}`}
      className={`py-4 text-dark justify-between
        block bg-white p-4 rounded flex items-center justify-center mb-4`}
    >
      <div className="w-full">
        <div className="text-lg text-center mb-2">{name}</div>
        <div className="text-gray text-sm text-center">
          <FormattedMessage
            id="dashboard.projects.list.stories_count"
            values={{
              storiesCount,
            }}
          />
        </div>
      </div>
    </Link>
  )
}

export default Project
