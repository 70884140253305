import React, { useCallback } from 'react'
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import fullLogo from 'assets/logo-full.png'
import { withIntl } from 'services/intl'

const LoginView = () => {
  const auth = getAuth()
  auth.languageCode = 'fr'

  const provider = new GoogleAuthProvider()
  const history = useHistory()

  const handleSubmit = useCallback(async () => {
    try {
      await signInWithPopup(auth, provider)

      const next =
        (history.location.state && history.location.state.next) || '/'

      history.push(next)
    } catch (error) {
      console.error(error)
    }
  }, [auth, history, provider])

  return (
    <div className="md:pt-24">
      <div className="bg-white py-24 px-8 md:w-1/2 lg:w-1/3 xl:w-1/4 md:mx-auto min-h-screen md:min-h-0">
        <div className="w-1/3 mx-auto mb-8">
          <img src={fullLogo} alt="Edito" title="Edito" className="w-full" />
        </div>

        <div className="text-center text-2xl mb-6">
          <FormattedMessage id="login.title" defaultMessage="Hello" />
        </div>

        <div className="text-center mb-12">
          <FormattedMessage
            id="login.instructions"
            defaultMessage="Connectez-vous à Edito pour poursuivre"
          />
        </div>

        <button
          onClick={handleSubmit}
          className="w-full h-12 rounded uppercase font-medium bg-primary
              hover:bg-primary-dark text-white"
        >
          <FormattedMessage
            id="login.signinWithGoogle"
            defaultMessage="Continuer avec Google"
          />
        </button>
      </div>
    </div>
  )
}

export default withIntl(require('./translations.json'))(LoginView)
