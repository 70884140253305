import React from 'react'
import { NavLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const MenuItem = ({ label, icon: Icon, ...props }) => {
  return (
    <NavLink
      {...props}
      className="block"
      style={{ paddingLeft: 2 }}
      activeClassName="border-l-2 border-primary font-medium"
      activeStyle={{ paddingLeft: 0, backgroundColor: '#F8FAFB1A' }}
    >
      <div className="flex items-center h-16 px-4 inline-block uppercase">
        <Icon className="mr-4" />
        <FormattedMessage id={`sidebar.${label}`} defaultMessage={label} />
      </div>
    </NavLink>
  )
}

export default MenuItem
