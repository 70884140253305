import React, { useEffect, useCallback, useReducer, useRef } from 'react'

const reducer = (state, action) => {
  switch (action.type) {
    case 'SHOW':
      return {
        ...state,
        visible: true,
      }

    case 'HIDE':
      return {
        ...state,
        visible: false,
      }

    default:
      return state
  }
}

export default function Dropdown({ trigger: Trigger, children, ...props }) {
  const [state, dispatch] = useReducer(reducer, {
    visible: false,
  })

  const thisElement = useRef(null)

  const handleWindowClick = useCallback((event) => {
    if (!thisElement.current) {
      return
    }

    if (
      event.target !== thisElement.current &&
      !thisElement.current.contains(event.target)
    ) {
      dispatch({ type: 'HIDE' })
    }
  }, [])

  useEffect(() => {
    window.addEventListener('click', handleWindowClick)
    window.addEventListener('touchstart', handleWindowClick)

    return () => {
      window.removeEventListener('click', handleWindowClick)
      window.removeEventListener('touchstart', handleWindowClick)
    }
  }, [handleWindowClick])

  const originalTriggerClickCallback = Trigger.props.onClick
  const TriggerClone = React.cloneElement(Trigger, {
    onClick: () => {
      dispatch({ type: state.visible ? 'HIDE' : 'SHOW' })

      if (originalTriggerClickCallback) {
        originalTriggerClickCallback(Trigger, arguments)
      }
    },
  })

  return (
    <div {...props} ref={thisElement}>
      {TriggerClone}
      {state.visible && children}
    </div>
  )
}
