import { useEffect, useState } from 'react'

const gapi = window.gapi
const GOOGLE_DRIVE_CLIENT_ID = process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID
const REQUIRED_SCOPE = 'https://www.googleapis.com/auth/drive.file'

export default function useContentAuthorization() {
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [accessToken, setAccessToken] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const grantScope = async (email) => {
    const currentUser = gapi.auth2.getAuthInstance().currentUser.get()
    const response = await currentUser.grantOfflineAccess({
      login_hint: email,
      redirect_uri: 'postmessage',
    })
    await currentUser.reloadAuthResponse()

    setIsAuthorized(true)

    return response
  }

  useEffect(() => {
    gapi.load('auth2', async () => {
      gapi.auth2
        .init({
          client_id: GOOGLE_DRIVE_CLIENT_ID,
          scope: REQUIRED_SCOPE,
        })
        .then(() => {
          const authInstance = gapi.auth2.getAuthInstance()
          const currentUser = authInstance.currentUser.get()
          const isAuthorized = currentUser.hasGrantedScopes(REQUIRED_SCOPE)

          setIsAuthorized(isAuthorized)
          setIsLoading(false)
        })
    })
  }, [])

  useEffect(() => {
    if (isAuthorized) {
      const authInstance = gapi.auth2.getAuthInstance()
      const currentUser = authInstance.currentUser.get()

      setAccessToken(currentUser.getAuthResponse(true).access_token)
    }
  }, [isAuthorized])

  return {
    isAuthorized,
    accessToken,
    grantScope,
    isLoading,
  }
}
