import React from 'react'
import Member from 'domains/members/list/item'
import { useProjectMember } from './hooks'
import { FormContext } from 'react-hook-form'

export default function ProjectMember({
  name,
  email,
  pictureUrl,
  role,
  roleId,
}) {
  const { methods, onUpdate, onDelete } = useProjectMember({
    role,
    roleId,
  })

  return (
    <FormContext {...methods}>
      <Member
        name={name}
        email={email}
        pictureUrl={pictureUrl}
        role={role}
        onDelete={onDelete}
        onUpdate={onUpdate}
      />
    </FormContext>
  )
}
