import React, { useCallback } from 'react'
import { withIntl } from 'services/intl'
import EmptyProjects from 'views/projects/empty/empty'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { PlusCircle } from 'react-feather'
import FilterDropdown from 'components/filter-dropdown/filter-dropdown'
import qs from 'query-string'
import ProjectsList from './projects-list'
import { useQuery } from 'react-query'
import api from 'services/api'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'

const useProjectsList = () => {
  const location = useLocation()
  const history = useHistory()
  const intl = useIntl()
  const [user] = useAuthState(getAuth())

  const currentStatusFilter = qs.parse(location.search).status || 'active'

  const valueToQs = {
    all: '',
    active: 'active',
  }

  const queryParams = {
    [valueToQs[currentStatusFilter]]: null,
    sort: '+name',
  }

  const { isLoading, data } = useQuery(['projects', queryParams], async () => {
    const accessToken = await user.getIdToken()

    return api.fetch(
      qs.stringifyUrl({
        url: '/projects',
        query: queryParams,
      }),
      { accessToken }
    )
  })

  const handleFilterChange = useCallback(
    ({ value }) => {
      history.replace({
        search: `?status=${value}`,
      })
    },
    [history]
  )

  return {
    isLoading,
    projects: data?.projects,
    currentFilterOptionValue: qs.parse(location.search).status || 'active',
    onFilterChange: handleFilterChange,
    intl,
  }
}

const ProjectListView = () => {
  const {
    isLoading,
    projects,
    currentFilterOptionValue,
    onFilterChange,
    intl,
  } = useProjectsList()

  const filterOptions = [
    {
      value: 'active',
      label: intl.formatMessage({
        id: 'projects.list.filters.status.option.active',
        defaultMessage: 'Active',
      }),
    },
    {
      value: 'all',
      label: intl.formatMessage({
        id: 'projects.list.filters.status.option.all',
        defaultMessage: 'All',
      }),
    },
  ]

  const filterOptionsMap = filterOptions.reduce(
    (acc, v) => ({
      ...acc,
      [v.value]: v.label,
    }),
    {}
  )

  if (isLoading) {
    return null
  }

  return (
    <>
      {!projects.length && <EmptyProjects />}

      {projects.length > 0 && (
        <div>
          <div className="flex mb-8 justify-between">
            <div className="flex items-center">
              <div className="text-silver text-xs font-medium mr-4 uppercase">
                <FormattedMessage
                  id="projects.list.filters.status.label"
                  defaultMessage="Status"
                />
              </div>

              <FilterDropdown
                options={filterOptions}
                className="w-32"
                value={{
                  value: currentFilterOptionValue,
                  label: filterOptionsMap[currentFilterOptionValue],
                }}
                onChange={onFilterChange}
              />
            </div>

            <Link
              to="/projects/create"
              className="uppercase text-primary flex items-center"
            >
              <PlusCircle className="mr-1" size={18} />

              <span className="font-medium text-sm">
                <FormattedMessage
                  id="projects.list.new"
                  defaultMessage="New project"
                />
              </span>
            </Link>
          </div>

          <ProjectsList projects={projects} />
        </div>
      )}
    </>
  )
}

export default withIntl(require('./translations.json'))(ProjectListView)
