import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as Illustration } from './empty-stories.svg'
import { Link, useRouteMatch } from 'react-router-dom'

const EmptyStories = () => {
  const match = useRouteMatch()
  return (
    <div className="xl:py-20">
      <Illustration
        className="w-full md:w-1/2 xl:w-1/3 mb-10 mx-auto"
        style={{ height: 250 }}
      />

      <div className="text-2xl text-center mb-2">
        <FormattedMessage
          id="projects.details.stories.empty.title"
          defaultMessage="No story created yet!"
        />
      </div>

      <p className="text-silver text-center font-light">
        <FormattedMessage
          id="projects.details.stories.empty.description"
          defaultMessage="You haven't created a story yet!"
        />

        <br />

        <FormattedMessage
          id="projects.details.stories.empty.cta"
          defaultMessage="<linkStories>Assign</linkStories> a story to this project or <linkStory>create</linkStory> a new one!"
          values={{
            linkStories: (...chunks) => (
              <Link to="/stories" className="text-primary">
                {chunks}
              </Link>
            ),
            linkStory: (...chunks) => (
              <Link to={`${match.url}/create`} className="text-primary">
                {chunks}
              </Link>
            ),
          }}
        />
      </p>
    </div>
  )
}

export default EmptyStories
