import React from 'react'
import { withIntl } from 'services/intl'
import { FormattedMessage } from 'react-intl'
import { FormContext } from 'react-hook-form'
import { Plus } from 'react-feather'
import MemberForm from 'domains/members/form'
import ProjectMembersList from './members-list'
import { useProjectMembers } from './hooks'

const ProjectMembers = () => {
  const {
    roles,
    isLoading,
    showForm,
    onShowForm,
    onHideForm,
    methods,
    onSubmit,
  } = useProjectMembers()

  const members = roles
    ? roles
        .filter((v) => v)
        .map((role) => ({
          name: [role.account.first_name, role.account.last_name].join(' '),
          role: role.name,
          pictureUrl: role.account.picture_url,
          email: role.account.email,
          roleId: role.id,
        }))
    : []

  return (
    <div className="bg-white p-6 -mx-3 lg:mx-0">
      {!isLoading && (
        <>
          <div className="text-xl mb-6">
            <FormattedMessage
              id="projects.details.members.title"
              defaultMessage="Project members"
            />
          </div>

          <div className="mb-3 xl:w-3/4">
            <ProjectMembersList members={members} />
          </div>

          {showForm && (
            <FormContext {...methods}>
              <MemberForm onCancel={onHideForm} onSubmit={onSubmit} />
            </FormContext>
          )}

          {!showForm && (
            <button className="flex items-center" onClick={onShowForm}>
              <span className="mr-5 rounded-full h-10 w-10 bg-background-light flex items-center justify-center text-primary">
                <Plus size={16} />
              </span>

              <span className="text-silver uppercase text-sm font-medium">
                <FormattedMessage
                  id="projects.details.members.invite"
                  defaultMessage="Invite more collaborators to your project"
                />
              </span>
            </button>
          )}
        </>
      )}
    </div>
  )
}

export default withIntl(require('./translations.json'))(ProjectMembers)
