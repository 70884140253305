import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import StoryListItem from 'domains/stories/list/item'

const StoriesList = ({ loading, stories }) => {
  if (loading) {
    return null
  }

  if (!stories.length) {
    return (
      <div className="h-24 md:h-48 justify-center flex items-center">
        <div>
          <FormattedMessage
            id="dashboard.stories.empty"
            defaultMessage="You haven't started any story yet."
          />

          <Link to="/stories/create" className="text-primary block text-center">
            <FormattedMessage
              id="dashboard.stories.cta"
              defaultMessage="Create one"
            />
          </Link>
        </div>
      </div>
    )
  }

  return (
    <div>
      {stories.map((story) => (
        <div
          className={`
            mb-1 md:mb-0 -mx-3 md:mx-0 px-3 md:px-0 bg-white md:border-t md:border-outline
            md:first:border-t-0
          `}
          key={story.id}
        >
          <Link to={`/stories/${story.id}`}>
            <StoryListItem
              title={story.title}
              eventAt={story.event_at}
              projectName={story.project && story.project.name}
              projectColor={story.project && story.project.color}
              ownerName={[
                story.account.first_name,
                story.account.last_name,
              ].join(' ')}
              owned={story.permissions.owns}
              ownerPicture={story.account.picture_url}
              status={story.status}
            />
          </Link>
        </div>
      ))}
    </div>
  )
}

export default StoriesList
