import React from 'react'
import { withIntl } from 'services/intl'
import StoriesDashboard from './stories'
import ProjectsDashboard from './projects'
import ActivityDashboard from './activities'

const DashboardView = () => {
  return (
    <div className="lg:flex h-full">
      <div className="lg:pr-4 lg:w-2/3 mb-8 lg:mb-0">
        <div className="mb-8">
          <StoriesDashboard />
        </div>

        <div>
          <ProjectsDashboard />
        </div>
      </div>

      <div className="lg:w-1/3 lg:pl-4">
        <ActivityDashboard />
      </div>
    </div>
  )
}

export default withIntl(require('./translations.json'))(DashboardView)
