import React from 'react'
import { useQuery } from 'react-query'
import { Briefcase } from 'react-feather'
import { FormattedMessage, useIntl } from 'react-intl'
import FilterDropdown from 'components/filter-dropdown/filter-dropdown'
import { useHistory, useLocation } from 'react-router-dom'
import api from 'services/api'
import qs from 'query-string'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'

const useProjectsFilter = () => {
  const [user] = useAuthState(getAuth())
  const location = useLocation()
  const intl = useIntl()
  const history = useHistory()

  const { isLoading, data } = useQuery(
    ['projects', { active: null, sort: '+name' }],
    async () => {
      const accessToken = await user.getIdToken()

      return api.fetch(
        qs.stringifyUrl({
          url: '/projects',
          query: {
            active: null,
            sort: '+name',
          },
        }),
        { accessToken }
      )
    }
  )

  const handleOptionSelect = ({ value }) => {
    history.replace({
      search: qs.stringify({
        ...qs.parse(location.search),
        project: value || undefined,
      }),
    })
  }

  return {
    isLoading,
    projects: data?.projects,
    location,
    intl,
    onChange: handleOptionSelect,
  }
}

export default function ProjectsFilter() {
  const { isLoading, projects, location, intl, onChange } = useProjectsFilter()

  if (isLoading || projects.length === 0) {
    return <span>&nbsp;</span>
  }

  const currentProjectSelected = qs.parse(location.search).project

  const blankOption = {
    value: '',
    label: intl.formatMessage({
      id: 'stories.projectsList.option.all',
      defaultMessage: 'All',
    }),
  }

  const projectOptions = projects
    .filter((project) => project.articles_count > 0)
    .map((project) => ({
      value: project.id,
      label: project.name,
    }))
  projectOptions.unshift(blankOption)

  const currentOption = currentProjectSelected
    ? {
        value: currentProjectSelected,
        label: projects[currentProjectSelected]?.name,
      }
    : blankOption

  return (
    <div className="flex items-center">
      <div className="text-silver text-sm font-medium mr-4 uppercase flex items-center">
        <Briefcase className="mr-2 inline" size={18} />

        <FormattedMessage
          id="stories.projectsList.label"
          defaultMessage="Project"
        />
      </div>

      <FilterDropdown
        options={projectOptions}
        className="w-48"
        value={currentOption}
        onChange={onChange}
      />
    </div>
  )
}
