import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as Illustration } from './empty.svg'
import { Link } from 'react-router-dom'

const EmptyProjects = () => {
  return (
    <div className="mt-20">
      <Illustration
        className="w-full md:w-1/2 xl:w-1/3 mb-10 mx-auto"
        style={{ height: 250 }}
      />

      <div className="text-2xl text-center mb-2">
        <FormattedMessage
          id="projects.empty.title"
          defaultMessage="No project created yet!"
        />
      </div>

      <div className="text-gray text-center font-light">
        <FormattedMessage
          id="projects.empty.description"
          defaultMessage="No project created yet!"
          values={{
            link: (...chunks) => (
              <Link to="/projects/create" className="text-primary">
                {chunks}
              </Link>
            ),
          }}
        />
      </div>
    </div>
  )
}

export default EmptyProjects
