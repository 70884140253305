import React, { useCallback, useState, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import copyToClipboard from 'copy-to-clipboard'
import StoryMembers from './members'

export default function ShareStory({ accessToken }) {
  const [copied, setCopied] = useState(null)
  const { current: copiedTimeout } = useRef(null)
  const storyLink = `${window.location.origin}/stories/${accessToken}/preview`

  const handleCopyClick = useCallback(() => {
    copyToClipboard(storyLink)

    clearTimeout(copiedTimeout)
    setCopied(true)

    setTimeout(() => setCopied(false), 2000)
  }, [copiedTimeout, storyLink])

  return (
    <div>
      <div className="text-xl mb-6">
        <FormattedMessage
          id="stories.details.share.title"
          defaultMessage="Share"
        />
      </div>

      <div className="pb-3">
        <div className="block mb-1 text-lg">
          <FormattedMessage
            id="stories.details.share.link"
            defaultMessage="Share link"
          />
        </div>

        <div className="block mb-1 text-gray text-sm">
          <FormattedMessage
            id="stories.details.share.link.explanation"
            defaultMessage="This link gives access to any invitee to read the content of your story. Nobody except you can modify your story's content."
          />
        </div>

        <div className="flex mb-1">
          <input
            className="border w-4/5 rounded-l font-medium p-4 border-outline"
            readOnly
            defaultValue={storyLink}
          />

          <button
            onClick={handleCopyClick}
            className="rounded-r bottom-0 px-4 bg-white border border-primary text-primary w-1/5 hover:border-primary-dark hover:text-primary-dark"
          >
            <FormattedMessage
              id="stories.details.share.copyLink"
              defaultMessage="Copy link"
            />
          </button>
        </div>

        {
          <div className="text-green">
            {copied ? (
              <FormattedMessage
                id="stories.details.share.copied"
                defaultMessage="Copied"
              />
            ) : (
              <span>&nbsp;</span>
            )}
          </div>
        }
      </div>

      <div className="border-t border-background pt-6">
        <StoryMembers />
      </div>
    </div>
  )
}
