import React from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'

const ProjectItem = ({ id, name, color }) => {
  const className = cx('border-l-2 text-white h-16 flex items-center px-4', {
    [`border-p-${color}`]: !!color,
    'border-black': !color,
  })

  return (
    <Link className={className} to={`/projects/${id}`}>
      {name}
    </Link>
  )
}

export default ProjectItem
