import React from 'react'
import Member from './member'

export default function StoryMembersList({ members }) {
  return (
    <div>
      {members.map((member) => (
        <Member
          key={member.roleId}
          name={member.name}
          pictureUrl={member.pictureUrl}
          role={member.role}
          email={member.email}
          roleId={member.roleId}
        />
      ))}
    </div>
  )
}
