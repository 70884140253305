import React from 'react'
import api from 'services/api'
import { useParams, Switch, Route, useRouteMatch, Link } from 'react-router-dom'
import cx from 'classnames'
import StoriesList from './stories-list'
import ProjectStoryDetails from './details'
import ProjectStoryCreate from './create'
import { FormattedMessage } from 'react-intl'
import qs from 'query-string'
import { PlusCircle } from 'react-feather'
import StoryFilters from 'domains/stories/filters'
import { useStoriesQueryString } from 'domains/stories/filters/use-query-string'
import { useQuery } from 'react-query'
import { usePagination } from 'components/pagination'
import Pagination from 'components/pagination'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'

const useProjectStories = () => {
  const { projectId } = useParams()
  const storyQueryParams = useStoriesQueryString()
  const match = useRouteMatch()
  const listMatch = useRouteMatch('/projects/:projectId/stories')
  const { page } = usePagination()
  const [user] = useAuthState(getAuth())

  const { data: projectData } = useQuery(['projects', projectId], async () => {
    const accessToken = await user.getIdToken()
    return api.fetch(`/projects/${projectId}`, { accessToken })
  })

  const { data: tagsData, isLoading: loadingTags } = useQuery(
    'tags',
    async () => {
      const accessToken = await user.getIdToken()
      return api.fetch('/tags', { accessToken })
    }
  )

  const queryParams = {
    ...storyQueryParams,
    sort: `+${projectData.project.articles_event_at_attribute}`,
    page,
    per_page: 10,
  }

  const { data, isLoading } = useQuery(
    ['stories', { ...queryParams, projectId }],
    async () => {
      const accessToken = await user.getIdToken()

      return api.fetch(
        qs.stringifyUrl(
          {
            url: `/projects/${projectId}/articles`,
            query: queryParams,
          },
          {
            arrayFormat: 'bracket',
          }
        ),
        { accessToken }
      )
    },
    {
      enabled: projectData,
    }
  )

  return {
    isLoading,
    loadingTags,
    stories: data?.articles,
    pagination: data?.meta.pagination,
    project: projectData?.project,
    tags: tagsData?.tags,
    listMatch,
    match,
  }
}

export default function ProjectStories() {
  const {
    isLoading,
    stories,
    project,
    tags,
    listMatch,
    match,
    loadingTags,
    pagination,
  } = useProjectStories()

  return (
    <div>
      <Switch>
        <Route path={`${match.path}`} exact>
          <div className="flex justify-between mb-4 items-center">
            {!loadingTags && <StoryFilters tags={tags.map((t) => t.name)} />}

            {project.permissions.update ? (
              <Link
                to={`${listMatch.url}/create`}
                className="uppercase text-primary flex items-center justify-end"
              >
                <PlusCircle className="mr-1" size={18} />

                <span className="font-medium text-sm">
                  <FormattedMessage
                    id="projects.details.stories.new"
                    defaultMessage="New story"
                  />
                </span>
              </Link>
            ) : (
              <span>&nbsp;</span>
            )}
          </div>

          <div
            className={cx('px-2 flex-1 -mx-2', {
              'lg:block': !isLoading && stories.length > 0,
            })}
          >
            <StoriesList fetching={isLoading} stories={stories} />

            {pagination && pagination.total_pages > 1 && (
              <div className="mt-8">
                <Pagination
                  currentPage={pagination.current_page}
                  previousPage={pagination.prev_page}
                  nextPage={pagination.next_page}
                  totalPages={pagination.total_pages}
                />
              </div>
            )}
          </div>
        </Route>

        <Route path={`${match.path}/create`}>
          <ProjectStoryCreate />
        </Route>

        <Route path={`${match.path}/:storyId`}>
          <ProjectStoryDetails />
        </Route>
      </Switch>
    </div>
  )
}
