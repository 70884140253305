import ErrorMessage from 'components/error-message'
import InlineEdit from 'components/inline-edit'
import InlineSelect from 'components/inline-select'
import React, { useCallback } from 'react'
import { Trash2 } from 'react-feather'
import { Controller } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { withIntl } from 'services/intl'
import { useMemberForm } from '../form/form'

function Member({ name, email, pictureUrl, role, onDelete, onUpdate }) {
  const intl = useIntl()
  const { accessOptions, methods } = useMemberForm()

  const handleDeleteClick = useCallback(
    (e) => {
      e.preventDefault()
      onDelete()
    },
    [onDelete]
  )

  return (
    <div className="flex items-center mt-3">
      <div className="h-10 w-10 mr-5 ">
        <img
          src={pictureUrl}
          alt={name}
          className="rounded-full w-full h-ful"
        />
      </div>

      <div className="lg:w-1/2 flex-1 lg:flex-initial lg:flex items-center">
        <div className="font-medium lg:w-1/2">{name}</div>
        <div className="text-gray lg:w-1/2">{email}</div>
      </div>

      <div className="w-24 mr-4 lg:mr-10">
        <form onSubmit={onUpdate}>
          <InlineEdit
            wrapperClassName="inline-block"
            wrapperActiveClassName="w-full"
            onBlur={onUpdate}
          >
            <>
              <span className="py-2 px-5 rounded bg-background text-silver">
                <FormattedMessage id={`members.access.${role}`} />
              </span>
            </>

            <>
              <Controller
                as={InlineSelect}
                className="block h-full border-0"
                options={accessOptions}
                control={methods.control}
                closeMenuOnSelect
                blurInputOnSelect
                openMenuOnFocus
                onChange={([selected]) => selected}
                name="name"
                placeholder={intl.formatMessage({
                  id: 'members.role.placeholder',
                })}
                rules={{ required: true }}
              />

              <ErrorMessage errors={methods.errors} name="name" />
            </>
          </InlineEdit>
        </form>
      </div>

      <button onClick={handleDeleteClick} className="text-silver" type="button">
        <Trash2 size={18} />
      </button>
    </div>
  )
}

export default withIntl(require('./translations'))(Member)
