import { useMutation, useQuery, useQueryCache } from 'react-query'
import api from 'services/api'
import qs from 'query-string'
import { useForm } from 'react-hook-form'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'

const NOOP = () => {}

export const useCreateStory = ({ onDone = NOOP }) => {
  const [user] = useAuthState(getAuth())
  const queryCache = useQueryCache()

  const { data } = useQuery(
    ['projects', { active: true, sort: '+name', account: true }],
    async () => {
      const accessToken = await user.getIdToken()
      const { account } = await api.fetch('/account', { accessToken })

      return api.fetch(
        qs.stringifyUrl({
          url: '/projects',
          query: {
            active: null,
            sort: '+name',
            account_id: account.id,
          },
        }),
        { accessToken }
      )
    }
  )

  const methods = useForm({
    defaultValues: {
      tags: [],
    },
  })

  const [createStory] = useMutation(
    async ({
      title,
      notes,
      dueOn,
      publishedOn,
      metaTitle,
      metaDescription,
      attachments,
      project,
      status,
    }) => {
      const accessToken = await user.getIdToken()

      const data = {
        article: {
          title,
          notes,
          due_on: dueOn?.toISOString(),
          published_on: publishedOn?.toISOString(),
          meta_title: metaTitle,
          meta_description: metaDescription,
          enclosed_files: attachments ? attachments.map((a) => a.value) : [],
          project_id: project?.id,
          status: status?.value,
        },
      }

      return api.fetch(`/articles`, {
        method: 'POST',
        body: JSON.stringify(data),
        accessToken,
      })
    },
    {
      onSuccess: (data) => {
        queryCache.setQueryData(['stories', data.article.id], data)
        queryCache.invalidateQueries('stories')

        onDone()
      },
      onError: (error) => {
        if (error.errorCode !== 'resource_invalid') {
          throw error
        }

        const fieldErrors = error?.details?.field_errors
        methods.setError(
          fieldErrors.map(({ field, code }) => ({
            name: field,
            message: code,
          }))
        )
      },
    }
  )

  const handleSubmit = methods.handleSubmit(createStory)

  return { onSubmit: handleSubmit, projects: data?.projects, methods }
}
