import React from 'react'
import { withIntl } from 'services/intl'
import Modal from 'components/modal'
import StorySummaryModal from './summary-modal'
import { XCircle, PlusCircle, Settings } from 'react-feather'
import CreateStoryModal from './create-modal'
import MonthCalendar from 'domains/calendar/month-calendar'
import ListCalendar from 'domains/calendar/list-calendar'
import { FormattedMessage } from 'react-intl'
import CalendarPanel from './panel'
import { useStoriesCalendar } from './hooks'
import { getEvents } from 'domains/calendar/helpers'

const StoriesCalendarView = () => {
  const {
    stories,
    selected,
    isModalOpen,
    closeModal,
    handleEventDrop,
    handleEventClick,
    handleNewEventClick,
    handleProjectClick,
    handleDisplayOptionClick,

    filter,
    settingsModal,
  } = useStoriesCalendar()

  const events = getEvents(stories, filter)

  return (
    <div>
      <div className="lg:hidden">
        <div className="mb-8 flex justify-between">
          <button
            onClick={settingsModal.openModal}
            className="uppercase flex items-center font-medium text-sm"
          >
            <Settings className="mr-1" size={18} />

            <FormattedMessage
              id="stories.calendar.showFilters"
              defaultMessage="Show filters"
            />
          </button>

          <button
            onClick={handleNewEventClick}
            className="uppercase text-primary flex items-center font-medium text-sm"
          >
            <PlusCircle className="mr-1" size={18} />

            <FormattedMessage
              id="stories.calendar.newStory"
              defaultMessage="New story"
            />
          </button>
        </div>
      </div>

      <div className="lg:flex">
        <div className="hidden lg:block mb-1 lg:mb-0 lg:w-1/5 xl:w-auto pt-10 pr-4">
          <CalendarPanel
            filter={filter}
            onProjectClick={handleProjectClick}
            onDisplayOptionClick={handleDisplayOptionClick}
          />
        </div>

        <div className="bg-white p-8 flex-1">
          <div style={{ minHeight: 700 }} className="hidden lg:block">
            <MonthCalendar
              events={events}
              onNewEventClick={handleNewEventClick}
              onEventDrop={handleEventDrop}
              onEventClick={handleEventClick}
            />
          </div>

          <div className="lg:hidden" style={{ minHeight: 700 }}>
            <ListCalendar
              events={events}
              onEventDrop={handleEventDrop}
              onEventClick={handleEventClick}
            />
          </div>

          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            className={`
              w-screen h-screen
              md:left-w-1/2 md:-ml-1/4 md:w-1/2 md:h-auto md:mt-8
              xl:-ml-1/6 xl:w-1/3
            `}
          >
            <div className="relative pt-12 pb-6 pr-6 pl-6 bg-white w-full h-full">
              <button
                className="absolute z-20 right-0 top-0 p-4"
                onClick={closeModal}
              >
                <XCircle size={24} />
              </button>

              {selected && <StorySummaryModal storyId={selected} />}
              {!selected && <CreateStoryModal onDone={closeModal} />}
            </div>
          </Modal>

          <Modal
            isOpen={settingsModal.isModalOpen}
            onRequestClose={settingsModal.closeModal}
            className={`
              w-screen h-screen
              md:left-w-1/2 md:-ml-1/4 md:w-1/2 md:h-auto md:mt-8
              xl:-ml-1/6 xl:w-1/3
            `}
          >
            <div className="bg-white h-full pt-12 pb-6 pr-6 pl-6">
              <button
                className="absolute z-20 right-0 top-0 p-4"
                onClick={settingsModal.closeModal}
              >
                <XCircle size={24} />
              </button>

              <CalendarPanel
                filter={filter}
                onProjectClick={handleProjectClick}
                onDisplayOptionClick={handleDisplayOptionClick}
              />
            </div>
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default withIntl(require('./translations.json'))(StoriesCalendarView)
