import React from 'react'
import { ErrorMessage as RHFErrorMessage } from 'react-hook-form'
import { useIntl } from 'react-intl'

const errorMessages = (errors, path) =>
  path.split('.').reduce((acc, p) => acc[p], errors)

export default function ErrorMessage({ errors, name, prefix = null }) {
  const intl = useIntl()
  const arr = ['form.errors', name]

  if (prefix) {
    arr.unshift(prefix)
  }

  return (
    <RHFErrorMessage errors={errors} name={name}>
      {({ message }) =>
        message && (
          <div className="text-pink text-sm mt-1">
            {intl.formatMessage({
              id: arr.concat([errorMessages(errors, name).message]).join('.'),
            })}
          </div>
        )
      }
    </RHFErrorMessage>
  )
}
