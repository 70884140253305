import React from 'react'
import { FormattedMessage } from 'react-intl'
import api from 'services/api'
import { useMutation, useQueryCache } from 'react-query'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'

const useArchiveStory = ({ archivedAt, storyId }) => {
  const queryCache = useQueryCache()
  const [user] = useAuthState(getAuth())

  const [archiveStory] = useMutation(
    async () => {
      const accessToken = await user.getIdToken()

      const path = archivedAt
        ? `/articles/${storyId}/unarchive`
        : `/articles/${storyId}/archive`

      return api.fetch(path, {
        method: 'POST',
        accessToken: accessToken,
      })
    },
    {
      onSuccess: (data) => {
        queryCache.invalidateQueries('stories')
        queryCache.setQueryData(['stories', data.article.id], data)
      },
    }
  )

  return {
    onArchive: archiveStory,
  }
}

export default function ArchiveStory({ archivedAt, storyId }) {
  const { onArchive } = useArchiveStory({ archivedAt, storyId })

  return (
    <div>
      <div className="mb-6">
        <div className="mb-1 text-xl">
          <FormattedMessage
            id="stories.details.archive.title"
            defaultMessage="Archive the story"
          />
        </div>

        <div className="text-gray">
          {archivedAt ? (
            <FormattedMessage
              id="stories.details.archive.archived.description"
              values={{ archivedAt }}
            />
          ) : (
            <FormattedMessage id="stories.details.archive.description" />
          )}
        </div>
      </div>

      <div>
        <button
          onClick={onArchive}
          className={`
          px-4 py-2
          border border-pink
          hover:border-pink
          hover:text-pink
          w-full rounded uppercase font-medium
        `}
        >
          {archivedAt ? (
            <FormattedMessage
              id="stories.details.archive.actions.unarchive"
              values={{ archivedAt: archivedAt }}
            />
          ) : (
            <FormattedMessage
              id="stories.details.archive.actions.archive"
              defaultMessage="Archive story"
            />
          )}
        </button>
      </div>
    </div>
  )
}
