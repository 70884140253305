import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import classnames from 'classnames'
import { useIntl, FormattedMessage } from 'react-intl'
import ErrorMessage from 'components/error-message/error-message'
import Select from 'components/select'
import { PROJECT_COLOR_OPTIONS } from 'services/project-colors'
import { components } from 'react-select'

const ColorOption = ({ value, ...props }) => {
  return (
    <components.Option {...props} value={value}>
      <span className={`w-6 h-6 rounded inline-block bg-p-${props.data.value}`}>
        &nbsp;
      </span>
    </components.Option>
  )
}

const ColorValue = ({ value, hasValue, ...props }) => {
  if (!hasValue) {
    return (
      <components.SingleValue {...props} value={value} hasValue={hasValue} />
    )
  }

  return (
    <components.SingleValue {...props} value={value}>
      <span className={`w-6 h-6 rounded inline-block bg-p-${props.data.value}`}>
        &nbsp;
      </span>
    </components.SingleValue>
  )
}

export default function ProjectSettingsForm({ onSubmit, showSavedMessage }) {
  const { register, errors, control, formState } = useFormContext()
  const intl = useIntl()

  return (
    <form onSubmit={onSubmit} className="p-6 bg-white lg:mx-0 lg:px-6">
      <div className="mb-8">
        <label className="mb-3 block">
          <FormattedMessage
            id="project.details.settings.name.label"
            defaultMessage="Name"
          />
        </label>

        <input
          ref={register({ required: 'required' })}
          name="name"
          className={classnames('border w-full rounded font-medium p-4', {
            'border-pink': !!errors.name,
            'border-outline': !errors.name,
          })}
          placeholder={intl.formatMessage({
            id: 'project.details.settings.name.placeholder',
            defaultMessage: 'Success stories',
          })}
        />

        <ErrorMessage errors={errors} name="name" />
      </div>

      <div className="mb-8">
        <label className="mb-3 block">
          <FormattedMessage
            id="project.details.settings.color.label"
            defaultMessage="Color"
          />
        </label>

        <Controller
          as={Select}
          name="color"
          control={control}
          className={classnames('mb-1 w-32', {
            'border-pink': !!errors.color,
          })}
          options={PROJECT_COLOR_OPTIONS}
          isClearable
          components={{
            Option: ColorOption,
            SingleValue: ColorValue,
          }}
          isSearchable={false}
        />

        <ErrorMessage errors={errors} name="color" />
      </div>

      <div>
        {showSavedMessage && (
          <div className="mb-1">
            <FormattedMessage
              id="project.details.settings.saved"
              defaultMessage="Saved"
            />
          </div>
        )}

        <button
          disabled={formState.isSubmitting}
          className={`
              w-full h-12 rounded uppercase font-medium bg-primary
              hover:bg-primary-dark text-white disabled:text-silver
              disabled:bg-background
            `}
        >
          <FormattedMessage
            id="project.details.settings.save"
            defaultMessage="Save"
          />
        </button>
      </div>
    </form>
  )
}
